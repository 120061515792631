import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { SwapService } from 'src/app/swap/services/swap.services';

enum RegisterControl {
    Email = 'email',
    VerificationCode = 'verificationCode',
}

@Component({
    selector: 'app-auth-swap-token',
    templateUrl: './auth-swap-token.component.html',
    styleUrls: ['./auth-swap-token.component.scss'],
})
export class AuthSwapTokenComponent implements OnInit {
    public formRegister: FormGroup;
    public inProgress: boolean;
    public control = RegisterControl;
    public circleId: string;

    constructor(private swapService: SwapService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.circleId = this.route.snapshot.params.circleId;
        this.createForm();
    }

    createForm() {
        this.formRegister = new FormGroup({
            [this.control.Email]: new FormControl('', {
                validators: [Validators.required, emailWithWhitespaceValidator],
            }),
        });
    }

    public async onLogin() {
        this.inProgress = true;
        var email = this.formRegister.get(this.control.Email).value;
        if (email) {
            await this.swapService.getSwapperByEmail(this.circleId, email);
        }
    }
}

export function emailWithWhitespaceValidator(control: FormControl) {
    const isValid = control.value.trim().match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
    return isValid
        ? null
        : {
              isValid: false,
          };
}
