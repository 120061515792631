export enum SwapperCommandType {
    name = 1,
    mobileNumber = 2,
    email = 3,
    activate = 4,
    deactivate = 5,
    delete = 6,
}

export class SwapperCommandDto {
    circleId: string;
    swapperId: string;
    type: SwapperCommandType;
    value: string;
}

export class SwapperCommandResultDto {
    circleId: string;
}
