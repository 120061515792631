import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PublicRoutes } from 'src/app/app-routing.model';
import { DisplayType } from 'src/app/components/button/button.component';
import { SwapperMeDto } from 'src/app/core/api/circle-swapper/circle-swapper-api.models';
import { CircleApiService } from 'src/app/core/api/circle/circle-api.service';
import { SwapperCommandDto, SwapperCommandType } from 'src/app/core/api/swapper-command/swapper-command-api.models';
import { SwapperCommandApiService } from 'src/app/core/api/swapper-command/swapper-command-api.service';
import { SwapStateService } from '../../core/swap-state.service';
import { SwapService } from '../services/swap.services';

export class SwapperCommand {
    type: SwapperCommandType;
    title: string;
    placeholder: string;
    text: string;
    buttonTitle: string;
    inputType: string;
    inputValue: any;
}

@Component({
    selector: 'app-swap-swapper',
    templateUrl: './swap-swapper.component.html',
    styleUrls: ['./swap-swapper.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SwapSwapperComponent implements OnInit, OnDestroy {
    public title = 'Gegevens swapper';

    public isLoading = false;

    public commandOptions: SwapperCommand[] = [
        {
            type: SwapperCommandType.name,
            title: 'Ik wil mijn naam wijzigen',
            placeholder: 'Je gecorrigeerde voornaam',
            buttonTitle: 'Wijzig naam',
            text: '',
            inputType: 'text',
            inputValue: '',
        },
        {
            type: SwapperCommandType.mobileNumber,
            title: 'Ik wil mijn mobielnummer wijzigen',
            placeholder: '0612345678',
            buttonTitle: 'Je nieuw mobielnummer',
            text: '',
            inputType: 'text',
            inputValue: '',
        },
        {
            type: SwapperCommandType.email,
            title: 'Ik wil mijn email wijzigen',
            placeholder: 'email',
            buttonTitle: 'Je gewijzigde email adres',
            text: '',
            inputType: 'text',
            inputValue: '',
        },
        {
            type: SwapperCommandType.activate,
            title: 'Ik wil mijn swap lidmaatschap voor cirkel "[circle]" activeren/pauseren    -   Ik wil mijn swap lidmaatschap voor cirkel "[circle]" activeren/pauseren Ik wil mijn swap lidmaatschap voor cirkel "[circle]" activeren/pauseren',
            placeholder: '',
            buttonTitle: '[action] je lidmaatschap',
            text: 'Je lidmaatschap is [status].',
            inputType: 'hidden',
            inputValue: '',
        },
        {
            type: SwapperCommandType.delete,
            title: 'Ik wil mijn swap lidmaatschap voor cirkel "[circle]" opzeggen',
            placeholder: '',
            buttonTitle: 'Lidmaatschap opzeggen',
            text: 'Ja, ik wil mijn lidmaatschap opzeggen.',
            inputType: 'checkbox',
            inputValue: false,
        },
    ];
    public selectedCommand: SwapperCommand = this.commandOptions[0];

    public circle: any;
    public swapperMe: SwapperMeDto;
    public commandSend: boolean = false;

    public subscriptions: Subscription = new Subscription();
    public displayTypes = DisplayType;

    public circleId: string;

    constructor(
        private route: ActivatedRoute,
        private circleApiService: CircleApiService,
        private stateService: SwapStateService,
        private swapService: SwapService,
        private router: Router,
        private swapperCommandApiService: SwapperCommandApiService
    ) {}

    ngOnInit() {
        this.circleId = this.route.snapshot.params.circleId;
        this.fetchCircle();
        const swapToken = this.stateService.getSwapperId(this.circleId);
        if (swapToken) {
            this.swapService.getSwapperById(this.circleId, swapToken);
        }

        this.subscriptions.add(
            this.stateService.getSwapperMe().subscribe((swapperMe) => {
                this.swapperMe = swapperMe;
            })
        );
    }

    circleResponse(circle) {
        this.circle = circle;
    }

    fetchCircle() {
        this.circleApiService
            .fetchPublicCircle(this.route.snapshot.params.circleId)
            .then(this.circleResponse.bind(this))
            .catch((reason: HttpErrorResponse) => {
                if (reason.status === 404) {
                    this.router.navigate([PublicRoutes.NotFound], { skipLocationChange: true });
                }
            });
    }

    public createWelcome() {
        const today = new Date();
        const hours = today.getHours();
        let welcome = '';
        if (hours < 6) {
            welcome = 'Goedenacht ';
        } else if (hours < 12) {
            welcome = 'Goedemorgen';
        } else if (hours < 18) {
            welcome = 'Goedemiddag ';
        } else {
            welcome = 'Goedenavond ';
        }

        return welcome;
    }

    public createTitle(title: string) {
        return title.replace('[circle]', this.circle.name);
    }

    public createText(command: SwapperCommand) {
        let text = command.text;
        if (command.type === SwapperCommandType.activate) {
            text = text.replace('[status]', this.swapperMe.isPaused ? 'gepauseerd' : 'actief');
        }
        return text;
    }

    public createButtonTitle(command: SwapperCommand) {
        let buttonTitle = command.buttonTitle;
        if (command.type === SwapperCommandType.activate) {
            buttonTitle = buttonTitle.replace('[action]', this.swapperMe.isPaused ? 'Activeer' : 'Pauseer');
        }
        return buttonTitle;
    }

    public isDisabled(type: SwapperCommandType) {
        switch (type) {
            case SwapperCommandType.name:
                return !this.selectedCommand.inputValue;
            case SwapperCommandType.mobileNumber:
                return this.selectedCommand.inputValue.length !== 10;
            case SwapperCommandType.email:
                return typeof this.selectedCommand.inputValue === 'string'
                    ? this.emailWithWhitespaceValidator(this.selectedCommand.inputValue)
                    : true;
            case SwapperCommandType.delete:
                return !this.selectedCommand.inputValue;
        }
        return false;
    }

    public onChangeCheckbox(event: any) {
        if (this.selectedCommand.type === SwapperCommandType.delete) {
            this.selectedCommand.inputValue = this.selectedCommand.inputValue === 'false' ? true : false;
        }
    }

    public logout() {
        this.stateService.logout(this.circleId);
    }

    public setCommand(command: any) {
        this.selectedCommand = command;
    }

    public numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }

    ngOnDestroy(): void {
        this.stateService.swapperMe = null;
        this.subscriptions.unsubscribe();
    }

    emailWithWhitespaceValidator(email: string) {
        const isValid = email.trim().match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
        return isValid
            ? null
            : {
                  isValid: false,
              };
    }

    onSubmit() {
        var swapperCommand = new SwapperCommandDto();
        swapperCommand.circleId = this.circleId;
        swapperCommand.swapperId = this.swapperMe.id;
        swapperCommand.value = this.selectedCommand.inputValue;
        swapperCommand.type = this.selectedCommand.type;
        if (this.selectedCommand.type === SwapperCommandType.activate) {
            swapperCommand.type = this.swapperMe.isPaused ? SwapperCommandType.activate : SwapperCommandType.deactivate;
        }
        this.swapperCommandApiService.postSwapperCommand(swapperCommand);
        this.commandSend = true;
        this.selectedCommand.type = SwapperCommandType.name;
        this.selectedCommand.inputValue = '';
    }
}
