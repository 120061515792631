import { Injectable } from '@angular/core';
import { CircleSwapperApiService } from 'src/app/core/api/circle-swapper/circle-swapper-api.service';
import { SwapperStatus } from 'src/app/core/api/shared/shared.enums';

@Injectable()
export class OwnerService {
    constructor(private circleSwapperApiService: CircleSwapperApiService) {}

    public async updateSwapperStatus(circleId: string, swapperId: string, swapperStatus: SwapperStatus) {
        return await this.circleSwapperApiService.updateSwapperStatus(circleId, swapperId, swapperStatus);
    }

    public async updateSwapperIsPaused(circleId: string, swapperId: string, isPaused: boolean) {
        return await this.circleSwapperApiService.updateSwapperIsPaused(circleId, swapperId, isPaused);
    }
}
