<h1>"{{ circle?.name }}"</h1>
<div *ngIf="circle?.status === 1">Zodra je 10 geactiveerde swappers hebt dan ontvang je labels voor 6 tassen.</div>

<div style="margin-top: 3rem; margin-bottom: 3rem; display: flex; justify-content: center; align-items: center" *ngIf="invitationUri">
    <input style="padding: 10px; width: 300px" #registerUri type="text" readonly [value]="invitationUri" (click)="registerUri.select()" />
    <app-button [display]="displayType.Link" style="margin-left: 15px; margin-right: 5px" (click)="copyToClipboard(registerUri)">
        <div style="display: flex">
            <fa-icon position="top" [icon]="icons.faClipboard" style="margin-right: 5px"></fa-icon>
            <div>kopieer</div>
        </div>
    </app-button>
    of
    <app-whats-app [text]="whatsAppMessage" linkText="Stuur uitnodigingscode via WhatsApp">verstuur via</app-whats-app>
</div>

<div>
    <table mat-table *ngIf="swappers" [dataSource]="swappers" style="margin: 10px auto; background: none">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef>Naam</th>
            <td mat-cell *matCellDef="let element">
                {{ element.name }}
                <div style="position: relative; float: right" class="bagIcon">
                    <fa-icon
                        position="top"
                        [icon]="icons.faBagShopping"
                        style="margin-right: 5px"
                        [className]="[getBagAlarm(element.currentOwnerOfBags)]"
                        *ngIf="element.currentOwnerOfBags.length"
                    ></fa-icon>
                    <div
                        *ngFor="let currentOwnerOfBag of element.currentOwnerOfBags"
                        style="text-align: left; position: absolute; left: 10px; padding: 10px; background-color: grey; color: white"
                    >
                        {{ currentOwnerOfBag.bagName }} ({{ currentOwnerOfBag.numberOfDays }} dag(en))
                    </div>
                </div>
            </td>
        </ng-container>

        <ng-container matColumnDef="mobileNumber">
            <th mat-header-cell *matHeaderCellDef>Mobiel</th>
            <td mat-cell *matCellDef="let element">
                <app-whats-app
                    [text]="'Hoi ' + element.name + ', '"
                    [mobile]="element.mobileNumber"
                    linkText="Stuur een WhatsApp-je"
                    [buttonText]="element.mobileNumber"
                ></app-whats-app>
            </td>
        </ng-container>

        <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef>Email</th>
            <td mat-cell *matCellDef="let element">
                {{ element.email }}
            </td>
        </ng-container>

        <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>Status</th>
            <td mat-cell *matCellDef="let element">
                <span *ngIf="element.isPaused">Gepauseerd</span>
                <span *ngIf="!element.isPaused">
                    {{ getStatus(element.status) }}
                </span>
                <button mat-icon-button [matMenuTriggerFor]="menu">
                    <fa-icon position="top" title="actie menu" [icon]="icons.faEllipsisV"></fa-icon>
                </button>
                <mat-menu #menu="matMenu">
                    <button mat-menu-item (click)="deleteSwapper(element)">
                        <fa-icon position="top" title="Verwijder" [icon]="icons.faTrash"></fa-icon>
                        Verwijder
                    </button>
                    <button mat-menu-item (click)="pauseSwapper(element)" *ngIf="!element.isPaused && element.status !== 2">
                        <fa-icon position="top" title="Pauseer" [icon]="icons.faMugHot"></fa-icon>
                        Pauseer
                    </button>
                    <button mat-menu-item *ngIf="element.status === 2" (click)="activateSwapper(element)">
                        <fa-icon position="top" title="Activeer" [icon]="icons.faPlug"></fa-icon>
                        Activeer
                    </button>
                    <button mat-menu-item *ngIf="element.isPaused" (click)="dePauseSwapper(element)">
                        <fa-icon position="top" title="Activeer" [icon]="icons.faPlug"></fa-icon>
                        Activeer
                    </button>
                </mat-menu>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
</div>
