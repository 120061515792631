<div class="main">
    <app-main-menu></app-main-menu>

    <div class="container">
        <router-outlet></router-outlet>
    </div>
    <footer>
        Swap Forward is een intiatief van Elien van Swieten. Heb je vragen? Mail naar
        <a href="mailto:info@swapforward.nl">info@swapforward.nl</a>
    </footer>
</div>
