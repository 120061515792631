import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, NgModel, Validators } from '@angular/forms';
import { SwapperRegistrationApiService } from 'src/app/core/api/swapper-registration/swapper-registration-api.service';
import { SwapperRegistrationService } from 'src/app/swap/services/swapper-registration-service';
import { DisplayType } from '../button/button.component';

enum RegisterControl {
    Email = 'email',
    EmailVerificationCode = 'emailVerificationCode',
}

enum VerifyEmailAddressStatus {
    None,
    VerificationCodeSend,
    EmailVerified,
}

export class VerifyEmailResult {
    public email: string;
    public emailVerificationCode: string;
}

const emailAddressUnderVerification = 'emailAddressUnderVerification';
const emailAddressUnderVerificationExpiration = 'emailAddressUnderVerificationExpiration';

@Component({
    selector: 'app-verify-email',
    templateUrl: './verify-email.component.html',
    styleUrls: ['./verify-email.component.scss'],
    providers: [SwapperRegistrationService, SwapperRegistrationApiService],
})
export class VerifyEmailComponent implements OnInit {
    public emailVerifyForm: FormGroup;
    public inProgress: boolean;
    public control = RegisterControl;
    public displayType = DisplayType;

    @Input()
    public sendVerificationCodeButtonText = 'Verzoek code om in te loggen';
    @Input()
    public verifyVerificationCodeButtonText = 'Code verifiëren om in te loggen';

    @Output()
    public emailVerified = new EventEmitter<VerifyEmailResult>();

    public emailAddressUnderVerification: string;
    public verifyEmailAddressStatus = VerifyEmailAddressStatus.None;
    public verifyEmailAddressStatuses = VerifyEmailAddressStatus;

    constructor(private swapperRegistrationService: SwapperRegistrationService) {}

    ngOnInit(): void {
        this.createForm();
    }

    createForm() {
        const dateString = localStorage.getItem(emailAddressUnderVerificationExpiration);
        const date = dateString ? new Date(dateString) : new Date();
        const compareDate = new Date();
        compareDate.setHours(compareDate.getHours() + 1);
        let email = '';
        if (date < compareDate) {
            email = localStorage.getItem(emailAddressUnderVerification) ?? '';
        }
        this.emailVerifyForm = new FormGroup({
            [this.control.Email]: new FormControl(email, {
                validators: [Validators.required, emailWithWhitespaceValidator],
            }),
            [this.control.EmailVerificationCode]: new FormControl(''),
        });

        if (email) {
            this.emailAddressUnderVerification = email;
            this.verifyEmailAddressStatus = VerifyEmailAddressStatus.VerificationCodeSend;
        }
    }

    public onSendVerifyCode() {
        this.swapperRegistrationService.sendVerificationCode(this.emailVerifyForm.value[this.control.Email]);
        this.emailAddressUnderVerification = this.emailVerifyForm.value[this.control.Email];
        localStorage.setItem(emailAddressUnderVerification, this.emailAddressUnderVerification);
        localStorage.setItem(emailAddressUnderVerificationExpiration, new Date().toISOString());
        this.emailVerifyForm.value[this.control.EmailVerificationCode] = '';

        this.verifyEmailAddressStatus = VerifyEmailAddressStatus.VerificationCodeSend;

        this.inProgress = true;
    }

    public onChangeEmailClick() {
        this.verifyEmailAddressStatus = VerifyEmailAddressStatus.None;
        this.emailVerifyForm.value[this.control.EmailVerificationCode] = '';
    }

    public onVerifyCode() {
        const email = this.emailVerifyForm.value[this.control.Email];
        const emailVerificationCode = this.emailVerifyForm.value[this.control.EmailVerificationCode];

        this.swapperRegistrationService
            .verifyVerificationCode(email, emailVerificationCode)
            .then(() => {
                this.verifyEmailAddressStatus = VerifyEmailAddressStatus.EmailVerified;
                localStorage.removeItem(emailAddressUnderVerification);
                this.emailVerified.emit({
                    email,
                    emailVerificationCode,
                });
            })
            .catch(() => {
                alert('Verificatie mislukt, probeer het nog een keer.');
            });
    }

    public showSendVerificationButton() {
        return this.verifyEmailAddressStatus === VerifyEmailAddressStatus.None;
    }

    public showVerifyCodeButton() {
        return this.verifyEmailAddressStatus === VerifyEmailAddressStatus.VerificationCodeSend;
    }

    public verificationCodeIsInvalid() {
        return this.emailVerifyForm.get(this.control.EmailVerificationCode).invalid;
    }
}

export function emailWithWhitespaceValidator(control: FormControl) {
    const isValid = control.value.trim().match('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$');
    return isValid
        ? null
        : {
              isValid: false,
          };
}
