import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DisplayType } from 'src/app/components/button/button.component';

@Component({
    templateUrl: './swap-admin-sign-up-owner.component.html',
    styleUrls: ['./swap-admin-sign-up-owner.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SwapAdminSignUpOwnerComponent {
    public formPostalCode: FormGroup;
    //public signUps: SignUpOwnerDto[];
    public subscriptions: Subscription = new Subscription();

    public displayTypes = DisplayType;

    constructor() {}

    // public async ngOnInit() {
    //     //   this.signUps = await this.onboardingApiService.getSignUpOwners();
    // }

    // public async startCircle(owner: SignUpOwnerDto) {}
}
