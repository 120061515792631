import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { QueryResult } from '../shared.models';
import { CommandResponse } from '../shared/api-result.models';
import { SwapperStatus } from '../shared/shared.enums';
import { StartCircleDto, SwapperMeDto } from './circle-swapper-api.models';

@Injectable()
export class CircleSwapperApiService {
    constructor(protected http: HttpClient) {}

    getSwapperByEmail(circleId: string, email: string): Promise<SwapperMeDto> {
        return this.http
            .get<QueryResult<SwapperMeDto>>(`${environment.swapFunctionBaseUri}circle/${circleId}/swapper?email=${email}`)
            .toPromise()
            .then((response) => response.data);
    }

    startCircle(startCircle: StartCircleDto): Promise<string> {
        return this.http.post<string>(`${environment.swapFunctionAuthBaseUri}circle/start-circle`, startCircle).toPromise();
    }

    getSwapperById(circleId: string, swapperId: string): Promise<SwapperMeDto> {
        return this.http
            .get<QueryResult<SwapperMeDto>>(`${environment.swapFunctionBaseUri}circle/${circleId}/swapper/${swapperId}`)
            .toPromise()
            .then((response) => response.data);
    }

    updateSwapperStatus(circleId: string, swapperId: string, status: SwapperStatus): Promise<SwapperMeDto> {
        const params = {
            status,
        };

        return this.http
            .post<SwapperMeDto>(`${environment.swapFunctionAuthBaseUri}circle/${circleId}/swapper/${swapperId}/update-status`, params)
            .toPromise();
    }

    updateSwapperIsPaused(circleId: string, swapperId: string, isPaused: boolean): Promise<SwapperMeDto> {
        const params = {
            isPaused,
        };

        return this.http
            .post<SwapperMeDto>(`${environment.swapFunctionAuthBaseUri}circle/${circleId}/swapper/${swapperId}/update-is-paused`, params)
            .toPromise();
    }
}
