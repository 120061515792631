import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-swap-admin',
    templateUrl: './swap-admin.component.html',
    styleUrls: ['./swap-admin.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SwapAdminComponent {
    public formPostalCode: FormGroup;

    public subscriptions: Subscription = new Subscription();

    constructor(private http: HttpClient, private snackBar: MatSnackBar, private router: Router) {}
}
