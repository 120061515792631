import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CircleDetailDto, SwapperDto } from 'src/app/core/api/circle/circle-api.models';
import { SwapperStatus } from 'src/app/core/api/shared/shared.enums';
import { OwnerService } from 'src/app/swap/services/owner.service';
import { CircleApiService } from 'src/app/core/api/circle/circle-api.service';
import { DisplayType } from 'src/app/components/button/button.component';
import { faBagShopping, faClipboard } from '@fortawesome/free-solid-svg-icons';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { faMugHot } from '@fortawesome/free-solid-svg-icons';
import { faPlug } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-swap-owner-circle',
    templateUrl: './swap-owner-circle.component.html',
    styleUrls: ['./swap-owner-circle.component.scss'],
    providers: [OwnerService],
})
export class SwapOwnerCircleComponent implements OnInit {
    constructor(
        private circleApiService: CircleApiService,
        private ownerService: OwnerService,
        private route: ActivatedRoute,
        private router: Router
    ) {}

    public circle: CircleDetailDto;
    public swappers: SwapperDto[];
    public displayedColumns: string[] = ['name', 'mobileNumber', 'email', 'status'];

    public icons = {
        faClipboard,
        faEllipsisV,
        faTrash,
        faMugHot,
        faPlug,
        faBagShopping,
    };
    public invitationUri: string;
    public whatsAppMessage: string;

    public displayType = DisplayType;

    async ngOnInit() {
        await this.loadData();
        this.invitationUri = `${window.location.origin}/register/${this.circle.registrationCode}`;
        this.whatsAppMessage = `Hoi , ${window.location.origin}/register/${this.circle.registrationCode}`;
    }

    async loadData() {
        this.circle = await this.circleApiService.fetchAuthCircle(this.route.snapshot.params.circleId);
        this.swappers = this.circle.swappers;
    }

    public getStatus(status: any) {
        switch (status) {
            case 2:
                return 'Geregistreerd';
            case 3:
                return 'Actief';
        }
    }

    copyToClipboard(inputElement: HTMLInputElement) {
        inputElement.select();
        document.execCommand('copy');
        inputElement.setSelectionRange(0, 0);
    }

    async activateSwapper(swapper: any) {
        await this.ownerService.updateSwapperStatus(this.circle.id, swapper.id, SwapperStatus.Accepted);
        this.loadData();
    }

    async dePauseSwapper(swapper: any) {
        await this.ownerService.updateSwapperIsPaused(this.circle.id, swapper.id, false);
        this.loadData();
    }

    async pauseSwapper(swapper: any) {
        await this.ownerService.updateSwapperIsPaused(this.circle.id, swapper.id, true);
        this.loadData();
    }

    async deleteSwapper(swapper: any) {
        if (confirm('Weet je het zeker?')) {
            await this.ownerService.updateSwapperStatus(this.circle.id, swapper.id, SwapperStatus.Deleted);
            this.loadData();
        }
    }

    getBagAlarm(element: any[]) {
        var sum = 0;
        element.forEach((e) => {
            sum += e.numberOfDays;
        });
        return sum > 30 ? 'bagAlarm' : '';
    }
}
