import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PublicRoutes } from 'src/app/app-routing.model';
import { DisplayType } from 'src/app/components/button/button.component';
import { Swapper } from 'src/app/core/api/circle-bag/circle-bag-api.models';
import { CircleBagApiService } from 'src/app/core/api/circle-bag/circle-bag-api.service';
import { SwapperMeDto } from 'src/app/core/api/circle-swapper/circle-swapper-api.models';
import { BagDto } from 'src/app/core/api/circle/circle-api.models';
import { BagStatus } from 'src/app/core/api/shared/shared.enums';
import { SwapStateService } from '../../core/swap-state.service';
import { SwapService } from '../services/swap.services';

@Component({
    selector: 'app-swap-bag',
    templateUrl: './swap-bag.component.html',
    styleUrls: ['./swap-bag.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SwapBagComponent implements OnInit, OnDestroy {
    public title = 'Swap forward';

    public isLoading = false;

    public bag: BagDto;
    public bagStatus = -1;

    public swapperMe: SwapperMeDto;
    public proposedOwner: Swapper;

    public bagStatusEnum = BagStatus;
    public displayTypes = DisplayType;

    public justAccepted = false;

    public subscriptions: Subscription = new Subscription();

    public circleId: string;

    private bagId: string;

    constructor(
        private route: ActivatedRoute,
        private circleBagApiService: CircleBagApiService,
        private stateService: SwapStateService,
        private swapService: SwapService,
        private router: Router
    ) {}

    ngOnInit() {
        this.circleId = this.route.snapshot.params.circleId;
        this.bagId = this.route.snapshot.params.bagId;
        const swapperId = this.stateService.getSwapperId(this.circleId);
        if (swapperId) {
            this.swapService.getSwapperById(this.circleId, swapperId);
        }

        this.subscriptions.add(
            this.stateService.getSwapperMe().subscribe((swapperMe) => {
                this.swapperMe = swapperMe;
                if (this.swapperMe) {
                    this.fetchBagStatus();
                } else {
                    this.bagStatus = BagStatus.None;
                }
            })
        );

        const messageString = 'justAccepted';
        const justAcceptedString = this.route.snapshot.queryParams[messageString];
        this.justAccepted = !!justAcceptedString;

        this.fetchBag();
    }

    async fetchBag() {
        await this.circleBagApiService
            .fetchCircleBagPublic(this.circleId, this.bagId)
            .then((response) => {
                this.bag = response;
            })
            .catch((reason: HttpErrorResponse) => {
                if (reason.status === 404) {
                    this.router.navigate([PublicRoutes.NotFound], { skipLocationChange: true });
                }
            });
    }

    public onReceivedBag() {
        this.justAccepted = true;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { justAccepted: 'true' },
        });

        this.swapToLoggedInModel();
    }

    public createWelcome() {
        const today = new Date();
        const hours = today.getHours();
        let welcome = '';
        if (hours < 6) {
            welcome = 'Goedenacht ';
        } else if (hours < 12) {
            welcome = 'Goedemorgen';
        } else if (hours < 18) {
            welcome = 'Goedemiddag ';
        } else {
            welcome = 'Goedenavond ';
        }

        return welcome;
    }

    private switchStatus(status: BagStatus) {
        switch (status) {
            case BagStatus.New:
            case BagStatus.UncontrolledSwapToConfirm:
            case BagStatus.SwapToConfirm:
                this.bagStatus = BagStatus.New;
                break;
            case BagStatus.ReadyToSwap:
                this.fetchProposedSwapForward();
                this.bagStatus = status;
                break;
            case BagStatus.ReadyToDress:
                this.bagStatus = status;
                break;
        }
    }

    public logout() {
        this.stateService.logout(this.circleId);
    }

    private async fetchProposedSwapForward() {
        this.proposedOwner = await this.circleBagApiService.fetchProposedSwapForward(this.bagId);
        this.proposedOwner.mobileNumberClean = this.proposedOwner.mobileNumber.replace('-', '');
    }

    async swapToLoggedInModel() {
        await this.circleBagApiService.swapToLogInModel(this.bagId);
        this.fetchBagStatus();
    }

    async onSetProposedSwap() {
        await this.circleBagApiService.onSetProposedSwap(this.bagId);
        this.fetchBagStatus();
    }

    async fetchBagStatus() {
        const bagStatusDto = await this.circleBagApiService.fetchBagStatus(this.bagId);
        this.bagStatus = bagStatusDto.status;
        this.switchStatus(this.bagStatus);
        //this.stateService.bagStatus = bagStatusDto.status;
    }

    ngOnDestroy(): void {
        this.stateService.swapperMe = null;
        this.subscriptions.unsubscribe();
    }
}
