import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { PublicRoutes } from 'src/app/app-routing.model';
import { CircleApiService } from 'src/app/core/api/circle/circle-api.service';

@Component({
    selector: 'app-swap-circle',
    templateUrl: './swap-circle.component.html',
    styleUrls: ['./swap-circle.component.scss'],
})
export class SwapCircleComponent implements OnInit, OnDestroy {
    public subscriptions: Subscription = new Subscription();
    public circle: any;
    public showWelcomeMessage: boolean;

    constructor(private router: Router, private route: ActivatedRoute, private circleApiService: CircleApiService) {}

    ngOnInit() {
        const messageString = 'message';
        const message = this.route.snapshot.queryParams[messageString];
        this.showWelcomeMessage = message === 'welcome';
        this.fetchCircle();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    circleResponse(circle) {
        this.circle = circle;
    }

    fetchCircle() {
        this.circleApiService
            .fetchPublicCircle(this.route.snapshot.params.circleId)
            .then(this.circleResponse.bind(this))
            .catch((reason: HttpErrorResponse) => {
                if (reason.status === 404) {
                    this.router.navigate([PublicRoutes.NotFound], { skipLocationChange: true });
                }
            });
    }
}
