import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export enum Roles {
    None = 0,
    Admin = 1,
    Owner = 2,
    Swapper = 3,
}

export class AuthorizationModel {
    public emailAddress: string;
    public nameIdentifier: string;
    public firstName: string;
    public newUser: string;
    public roles: Roles[];
}

@Injectable()
export class StateService {
    private me$ = new BehaviorSubject<AuthorizationModel>(null);

    constructor() {}

    public set me(value: AuthorizationModel) {
        this.me$.next(value);
    }

    public getMe(): Observable<AuthorizationModel> {
        return this.me$.asObservable();
    }
}
