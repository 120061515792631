import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { QueryResult } from '../shared.models';
import { CommandResponse } from '../shared/api-result.models';
import { RegisterSwapperDto, RegistrationCodeVerificationResponseDto, RegistrationResponseDto } from './swapper-registration-api.models';

@Injectable()
export class SwapperRegistrationApiService {
    constructor(protected http: HttpClient) {}

    async checkRegistrationCode(registrationCode: string): Promise<RegistrationCodeVerificationResponseDto> {
        const params = {
            registrationCode,
        };

        return await this.http
            .post<QueryResult<RegistrationCodeVerificationResponseDto>>(
                `${environment.swapFunctionBaseUri}swapper-registration/get-registration-circle`,
                params
            )
            .toPromise()
            .then((response) => response.data);
    }

    sendVerificationCode(emailAddress: string): Promise<object> {
        const params = {
            emailAddress,
        };

        return this.http.post(`${environment.swapFunctionBaseUri}swapper-registration/send-verification-code`, params).toPromise();
    }

    verifyVerificationCode(email: string, code: string): Promise<object> {
        const params = {
            email,
            emailVerificationCode: code,
        };

        return this.http.post(`${environment.swapFunctionBaseUri}swapper-registration/verify-code`, params).toPromise();
    }

    registerSwapper(registerSwapper: RegisterSwapperDto): Promise<string> {
        return this.http
            .post<CommandResponse>(`${environment.swapFunctionBaseUri}swapper-registration/register-swapper`, registerSwapper)
            .toPromise()
            .then((response) => response.newId);
    }
}
