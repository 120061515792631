import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { OwnerRoutes } from 'src/app/app-routing.model';
import { DisplayType } from 'src/app/components/button/button.component';
import { CircleDto } from 'src/app/core/api/circle/circle-api.models';
import { CircleApiService } from 'src/app/core/api/circle/circle-api.service';

@Component({
    selector: 'app-swap-owner-home',
    templateUrl: './swap-owner-home.component.html',
    styleUrls: ['./swap-owner-home.component.scss'],
})
export class SwapOwnerHomeComponent implements OnInit {
    constructor(private circleApiService: CircleApiService, private router: Router) {}

    public circles: CircleDto[];

    public displayTypes = DisplayType;
    async ngOnInit() {
        this.circles = await this.circleApiService.fetchAuthCircles();
    }

    public onClick(circleId: string) {
        this.router.navigate([OwnerRoutes.OwnerHome, circleId]);
    }
}
