<h1>Doe mee</h1>
<p style="margin-bottom: 20px">
    Verzamel een aantal dames/heren op fietsafstand van elkaar die enthousiast worden van duurzaam kleding delen. Vanaf 10 deelnemers kan je al
    beginnen!

    <span style="margin-bottom: 1.5rem" *ngIf="(loggedInUser$ | async) === null">
        <app-link [routerLink]="['/aanmelden']" class="inline">Meldt hier hier aan</app-link>
        en dan regelen wij de rest (kaartjes met QR codes voor aan de tassen en je eigen online omgeving). Dan kunnen jullie binnenkort
        lekker Swappen!
    </span>
</p>

<p style="margin-bottom: 1.5rem" *ngIf="(loggedInUser$ | async) === null">
    Mocht je al beheerder van je eigen groep zijn dan kun je
    <app-link [routerLink]="['/owner']" class="inline">hier inloggen</app-link>
    en je groep beheren.
</p>
