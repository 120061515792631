<header>
    <div class="cui--flex cui--flex-wrap" style="margin: 0 10px">
        <div class="cui--flex cui--flex-row cui--media-mobile">
            <app-link [routerLink]="['zo-werkt-het']" routerLinkActive="swap__active">Zo werkt het</app-link>
            <app-link [routerLink]="['mijn-voordeel']" routerLinkActive="swap__active">Mijn voordeel</app-link>
            <app-link [routerLink]="['missie']" routerLinkActive="swap__active">Missie</app-link>
            <app-link [routerLink]="['contact']" routerLinkActive="swap__active">Contact</app-link>
        </div>
        <div class="cui--flex-break"></div>
        <div class="menu cui--flex cui--flex-row">
            <div class="cui--flex cui--flex-justify-end menu-block cui--media-desktop">
                <app-link [routerLink]="['zo-werkt-het']" routerLinkActive="swap__active">Zo werkt het</app-link>
                <app-link [routerLink]="['mijn-voordeel']" routerLinkActive="swap__active">Mijn voordeel</app-link>
            </div>

            <div>
                <app-link [routerLink]="['']">
                    <img src="assets/images/logo.png" class="img" />
                </app-link>
            </div>

            <div class="cui--flex cui--flex-justify-start menu-block cui--media-desktop">
                <app-link [routerLink]="['missie']" routerLinkActive="swap__active">Missie</app-link>
                <app-link [routerLink]="['contact']" routerLinkActive="swap__active">Contact</app-link>
            </div>
        </div>
        <div class="cui--flex-break"></div>
        <div *ngIf="isLoggedIn()" class="menu cui--flex cui--flex-row">
            <app-link [routerLink]="['admin']" *ngIf="hasAdmin()" routerLinkActive="swap__active">Admin</app-link>
            <app-link [routerLink]="['owner']" *ngIf="hasOwner()" routerLinkActive="swap__active">Swap cirkel</app-link>
            <div>
                <a class="link" href="/logout">Logout</a>
            </div>
        </div>

        <!-- <div *ngIf="!isLoggedIn()" class="menu cui--flex cui--flex-row">
            <app-link [routerLink]="['doe-mee']" routerLinkActive="swap__active">Doe mee</app-link>
        </div> -->
    </div>
</header>
