<mat-card class="card rose">
    <app-button (click)="onOwnerClick()">Owner</app-button>
    <mat-form-field appearance="fill">
        <mat-label>Kies een tas</mat-label>
        <mat-select (selectionChange)="selectBag($event.value)" multiple>
            <mat-option *ngFor="let bag of bags" [value]="bag" [style.color]="bag.color">
                <b>
                    {{ bag.name }}
                </b>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Aantal swaps</mat-label>
        <mat-select style="text-align: left" (selectionChange)="selectNumberOfPathSteps($event.value)" [(value)]="selectedNumberOfSteps">
            <mat-option *ngFor="let number of numberOfPathSteps" [value]="number">
                <b>{{ number }} swap(s)</b>
            </mat-option>
        </mat-select>
    </mat-form-field>

    <ng-container *ngIf="selectedBag">
        <h1>
            {{ selectedBag.name }}
        </h1>
        <div>
            <a [href]="createQrCardLink(selectedBag.id)" target="_blank">Qr Card {{ selectedBag.name }}</a>
        </div>

        <div>
            <app-button (click)="onBagClick()">Swap {{ selectedBag.name }}</app-button>
        </div>
    </ng-container>
</mat-card>
<div id="map" #map></div>
