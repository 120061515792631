import { Component, OnInit, ViewChild, ElementRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';

import * as L from 'leaflet';
import * as esri from 'esri-leaflet';
import 'leaflet.markercluster';
import { SwapperDto } from '../../core/api/circle/circle-api.models';
import { CircleBagApiService } from '../../core/api/circle-bag/circle-bag-api.service';
import { CircleApiService } from 'src/app/core/api/circle/circle-api.service';
import { environment } from 'src/environments/environment';
import { BagModel } from './swap-admin-circle.models';
import { SwapperStatus } from 'src/app/core/api/shared/shared.enums';

@Component({
    selector: 'app-swap-admin-circle',
    templateUrl: './swap-admin-circle.component.html',
    styleUrls: ['./swap-admin-circle.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SwapAdminCircleComponent implements OnInit, OnDestroy {
    public formPostalCode: FormGroup;
    public title = 'swap forward';

    @ViewChild('map', { static: true }) mapElement: ElementRef;
    map: L.Map;
    currentLocation: any;
    currentMarker: L.Marker;
    geoWatcher: number;

    lineStringFeatureGroup = L.featureGroup();
    swapperGroup = L.featureGroup();

    selectedBag: BagModel;
    selectedBags: BagModel[];

    featureGroupCache: { [id: string]: any };

    selectedNumberOfSteps = 3;
    numberOfPathSteps = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

    bags: BagModel[] = [];
    swappers: SwapperDto[] = [];
    swappersRanked = [];

    public isLoading = false;

    public topModels: any;

    public subscriptions: Subscription = new Subscription();

    constructor(
        private route: ActivatedRoute,
        private circleApiService: CircleApiService,
        private circleBagApiService: CircleBagApiService,
        private router: Router
    ) {}

    async ngOnInit() {
        const options = {
            enableHighAccuracy: true,
            timeout: Infinity,
            maximumAge: 0,
        };

        this.map = L.map(this.mapElement.nativeElement).setView([52.092876, 5.10448], 4);
        esri.basemapLayer('Topographic').addTo(this.map);

        this.lineStringFeatureGroup = L.featureGroup();
        this.map.addLayer(this.lineStringFeatureGroup);

        this.swapperGroup = L.featureGroup();
        // this.swapperGroup = L.markerClusterGroup({
        //     maxClusterRadius: 10,
        //     iconCreateFunction: function (cluster) {
        //         return L.divIcon({
        //             className: 'swapper-icon',
        //             iconSize: new L.Point(16, 16),
        //             html: cluster.getChildCount(),
        //         });
        //     },
        // });
        this.map.addLayer(this.swapperGroup);

        const circle = await this.circleApiService.fetchAuthCircle(this.route.snapshot.params.circleId);
        this.bags = circle.bags.map((b, i) => new BagModel(b, i));
        this.swappers = circle.swappers;
        this.drawSwappers();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    selectNumberOfPathSteps(numberOfSteps: number) {
        this.selectedNumberOfSteps = numberOfSteps;
        this.selectBag(this.selectedBags);
    }

    async selectBag(bags: BagModel[]) {
        this.selectedBags = bags;
        this.resetMap();

        if (!bags.length) {
            this.selectedBag = null;
            return;
        }
        this.selectedBag = bags[bags.length - 1];
        for (const bag of bags) {
            const bagDetail = await this.circleBagApiService.fetchCircleBag(this.route.snapshot.params.circleId, bag.id);

            this.swappersRanked = bagDetail.swappersRanked;

            const ownerLayer = this.drawPath(bagDetail.ownerLineString, bag.color, this.selectedNumberOfSteps, false);
            this.lineStringFeatureGroup.addLayer(ownerLayer);

            const proposedLayer = this.drawPath(bagDetail.proposedOwnerLineString, bag.color, 1, true);
            this.lineStringFeatureGroup.addLayer(proposedLayer);
        }
        this.drawSwappers();
    }

    createQrCardLink(bagId: string) {
        return `${environment.swapFunctionBlobStorageUri}qr-card/${bagId}.png`;
    }

    onBagClick() {
        return this.router.navigate([this.route.snapshot.params.circleId, this.selectedBag.id]);
    }

    onOwnerClick() {
        return this.router.navigate(['owner', this.route.snapshot.params.circleId]);
    }

    resetMap() {
        this.lineStringFeatureGroup.eachLayer((layer) => {
            this.map.removeLayer(layer);
        });
    }

    drawPath(lineString: any, color: string, length: number, dashed: boolean) {
        if (lineString.coordinates) {
            lineString.coordinates = lineString.coordinates.slice(Math.max(lineString.coordinates.length - length - 1, 0));
        }
        const proposedOwnerFeature = {
            type: 'Feature',
            properties: {
                color,
                dashArray: dashed ? '30,15' : '1:1',
                lineJoin: 'round',
                weight: 3,
            },
            geometry: lineString,
        };

        const lineStringLayer = L.geoJSON(proposedOwnerFeature, {
            style: (feature) => {
                return {
                    color: feature.properties.color,
                    dashArray: feature.properties.dashArray,
                    lineJoin: feature.properties.lineJoin,
                    weight: feature.properties.weight,
                };
            },
        });
        return lineStringLayer;
    }

    drawSwappers() {
        this.swapperGroup.eachLayer((layer) => {
            this.map.removeLayer(layer);
        });

        for (const swapper of this.swappers) {
            const geojsonMarkerOptions = {
                radius: 8,
                fillColor: '#fac8c0',
                color: '#000',
                weight: 1,
                opacity: 1,
                fillOpacity: 0.8,
            };
            if (swapper.isPaused || swapper.status !== SwapperStatus.Accepted) {
                geojsonMarkerOptions.fillColor = '#efefef';
            }

            const rank = this.swappersRanked.find((r) => r.swapperId === swapper.id);

            let content = `${swapper.name}: ${swapper.mobileNumber} `;
            if (swapper.status === SwapperStatus.Registered) {
                content += ' (registered)';
            }
            if (swapper.isPaused) {
                content += ' (paused)';
            }
            if (swapper.currentOwnerOfBags.length) {
                const bag = this.bags.find((b) => b.name === swapper.currentOwnerOfBags[0].bagName);
                geojsonMarkerOptions.fillColor = bag.color;
                content = `${swapper.name}: ${swapper.mobileNumber} <br/>
                Tas: ${bag.name} (${swapper.currentOwnerOfBags[0].numberOfDays} dagen)`;
            }
            if (rank) {
                rank.calculations.forEach((element) => {
                    content += `<br/>${element.item1} = ${element.item2} (${element.item3})`;
                });
            }

            const swapperFeature = {
                type: 'Feature',
                properties: {
                    popupContent: content,
                },
                geometry: swapper.location,
            };

            const swapperStartLayer = L.geoJSON(swapperFeature, {
                onEachFeature: this.onEachFeature,
                pointToLayer: (feature, latlng) => {
                    return L.circleMarker(latlng, geojsonMarkerOptions);
                },
            });

            this.swapperGroup.addLayer(swapperStartLayer);

            const rankIndex = this.swappersRanked.findIndex((r) => r.swapperId === swapper.id);

            if (rankIndex > -1) {
                const className = rankIndex === 0 ? 'proposed-swapper-icon' : 'swapper-icon';
                const swapperIcon = L.divIcon({ className, iconSize: new L.Point(16, 16), html: rankIndex + 1 });
                const swapperStartLayer2 = L.geoJSON(swapperFeature, {
                    onEachFeature: this.onEachFeature,
                    pointToLayer: (feature, latlng) => {
                        return L.marker(latlng, {
                            icon: swapperIcon,
                        });
                    },
                });
                this.swapperGroup.addLayer(swapperStartLayer2);
            }
        }

        const bounds = this.swapperGroup.getBounds();
        this.map.fitBounds(bounds, { maxZoom: 15 });
    }

    createSwapperLayer(marker: any, content: string, location: any) {
        const swapperFeature = {
            type: 'Feature',
            properties: {
                popupContent: content,
            },
            geometry: location,
        };

        return L.geoJSON(swapperFeature, {
            onEachFeature: this.onEachFeature,
            pointToLayer: (feature, latlng) => {
                return marker;
            },
        });
    }

    onEachFeature(feature, layer) {
        if (feature.properties && feature.properties.popupContent) {
            layer.bindPopup(feature.properties.popupContent);
        }
    }
}
