<form [formGroup]="form" *ngIf="circle">
    <p style="margin-bottom: 1rem">Registreer jezelf voor Swap Cirkel '{{ circle.name }}'.</p>
    <app-mobile-comment>
        <span style="text-decoration: underline">Let op!</span>
        Voer de registratie uit op je mobiele telefoon.
    </app-mobile-comment>

    <div [ngSwitch]="activeRegistrationStep" style="margin-top: 1rem">
        <ng-container *ngSwitchCase="registrationStepTypes.Email">
            <span style="font-weight: 700">Stap 1/3: email adres</span>

            <p>
                Je email adres is niet zichtbaar voor alle Swappers. We gebruiken je email adres alleen om te bevestigen dat jij het bent.
            </p>

            <app-verify-email
                sendVerificationCodeButtonText="Verificatiecode verzenden"
                verifyVerificationCodeButtonText="Code verifiëren"
                (emailVerified)="onEmailVerified($event)"
            ></app-verify-email>
        </ng-container>
        <ng-container *ngSwitchCase="registrationStepTypes.Coordinates">
            <span style="font-weight: 700">Stap 2/3: bevestig je locatie</span>
            <p>
                Je locatie is niet zichtbaar voor alle Swappers. We hebben je locatie nodig zodat het algoritme kan uitrekenen welke
                volgende Swapper zich dicht bij jou bevind. Zo kan je de tas altijd lopend of fietsend wegbrengen, lekker duurzaam!
            </p>
            <app-location-input
                *ngIf="locationInputActive"
                (locationInputDeactivated)="onLocationInputDeactivated()"
                (locationInputAccepted)="onLocationInputAccepted($event)"
                [center]="circle.center"
            ></app-location-input>
            <app-button (click)="onLocationInputActivated()">locatie</app-button>
        </ng-container>
        <ng-container *ngSwitchCase="registrationStepTypes.Contact">
            <span style="font-weight: 700">Stap 3/3: contact gegevens</span>
            <p>
                De Swapper die een tas naar jou moet brengen krijgt je voornaam en mobiele telefoonnummer. Zo kan je samen een handige tijd
                en plaats afspreken om de Swap tas te overhandigen.
            </p>
            <input type="text" placeholder="Voornaam" [formControlName]="control.FirstName" class="swapInput" />
            <input
                type="text"
                placeholder="0612345678"
                [formControlName]="control.Mobile"
                (keypress)="numberOnly($event)"
                class="swapInput"
            />
            <app-button (click)="onRegister()" [disabled]="!formIsValid()">Registreer</app-button>
        </ng-container>
    </div>
</form>
