<h1>Mijn voordeel</h1>
<p>
    Het lijkt een kleine keuze, maar je impact is groter dan je denkt. Je durft een verschil te maken. Geen fast fashion, waar arbeiders uit
    lage lonen landen voor hebben geleden, geen vervuilend transport maar een geweldig mooi kledingitem wat een tweede leven krijgt.
    Bijkomend voordeel het kost jou niets.
</p>
<ol>
    <li>Gratis</li>
    <li>Bijdrage aan duurzaamheid</li>
    <li>Sociale lokale contacten bij het doorgeven van de tas</li>
    <li>Anderen blij maken met kleding van jou en dat maakt jou weer blij</li>
    <li>Lekker op je gemak thuis passen</li>
</ol>
<p style="margin-bottom: 1.5rem" *ngIf="(loggedInUser$ | async) === null">
    Maak het verschil.
    <app-link [routerLink]="['/doe-mee']" class="inline">Swap met ons mee</app-link>
    en word net zo enthousiast!
</p>
