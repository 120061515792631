import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild, ViewEncapsulation } from '@angular/core';
import * as L from 'leaflet';
import * as esri from 'esri-leaflet';
import { LocationService } from 'src/app/core/location.service';
import { GeoJsonDto } from 'src/app/core/api/shared.models';

@Component({
    selector: 'app-location-input',
    templateUrl: './location-input.component.html',
    styleUrls: ['./location-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LocationInputComponent implements OnInit {
    @Output() public locationInputDeactivated = new EventEmitter();
    @Output() public locationInputAccepted = new EventEmitter();

    @Input() public center: GeoJsonDto;

    @ViewChild('map', { static: true }) mapElement: ElementRef;
    map: L.Map;
    currentLocation: any;
    currentMarker: L.Marker;
    locationShared = true;

    constructor(private locationService: LocationService) {}

    public async ngOnInit() {
        const markerIconOptionUser = {
            draggable: true,
            icon: L.icon({
                iconSize: [19, 31],
                shadowSize: [31, 31],
                iconAnchor: [9.5, 31],
                popupAnchor: [0, -34],
                iconUrl: `assets/markers/marker-icon-2x-orange.png`,
                shadowUrl: `assets/markers/marker-shadow.png`,
            }),
        };

        let position = await this.locationService.getCurrentPosition();

        if (!position) {
            this.locationShared = false;
            if (this.center) {
                position = {
                    lat: this.center.coordinates[1],
                    lng: this.center.coordinates[0],
                };
            } else {
                position = {
                    lat: 52.213,
                    lng: 5.2794,
                };
            }
        }

        this.map = L.map(this.mapElement.nativeElement).setView([position.lat, position.lng], 17);
        esri.basemapLayer('Topographic').addTo(this.map);

        this.currentLocation = [position.lat, position.lng];
        this.currentMarker = L.marker(this.currentLocation, markerIconOptionUser)
            .addTo(this.map)
            .bindPopup('Klopt je locatie niet, verplaats dan deze oranje marker.')
            .openPopup();
    }

    public onLocationInputDeactivated() {
        this.locationInputDeactivated.emit();
    }

    public onLocationInputAccepted() {
        this.locationInputAccepted.emit(this.currentMarker.getLatLng());
    }
}
