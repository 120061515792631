import { BagDto } from 'src/app/core/api/circle/circle-api.models';

const colors = ['#009fd4', '#a35b02', '#00aa55', '#939393', '#AA8F00', '#B381B3', '#E76E3C', '#D47500'];

export class BagModel {
    public id: string;
    public name: string;
    public color: string;

    constructor(bag: BagDto, index: number) {
        this.id = bag.id;
        this.name = bag.name;
        this.color = colors[Math.min(index, colors.length)];
    }
}
