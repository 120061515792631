import { HttpClient } from '@angular/common/http';
import { Injectable, Injector, OnDestroy } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { QueryResult } from './api/shared.models';
import { BadRequestEventArgs } from './api/shared/api-result.models';
import { AppEvent } from './api/shared/app.events';
import { EventService } from './event.service';
import { AuthorizationModel, StateService } from './state.service';

export enum MsalEvents {
    LoginSuccess = 'msal:loginSuccess',
    LoginFailure = 'msal:loginFailure',
    AcquireTokenSuccess = 'msal:acquireTokenSuccess',
    AcquireTokenFailure = 'msal:acquireTokenFailure',
    NotAuthorized = 'msal:notAuthorized',
}

@Injectable()
export class AppService implements OnDestroy {
    private subscriptions = new Subscription();

    constructor(private injector: Injector) {}

    // private get msalService(): MsalService {
    //     return this.injector.get(MsalService);
    // }

    // private readonly logger = this.msalService.getLogger();

    private get eventService(): EventService {
        return this.injector.get(EventService);
    }

    private get http(): HttpClient {
        return this.injector.get(HttpClient);
    }

    private get stateService(): StateService {
        return this.injector.get(StateService);
    }

    private get matSnackBar(): MatSnackBar {
        return this.injector.get(MatSnackBar);
    }

    public ngOnDestroy() {
        this.subscriptions.unsubscribe();
    }

    private meResponse(me: QueryResult<AuthorizationModel>) {
        this.stateService.me = me.data;
    }

    public async init(): Promise<void> {
        console.log('init');
     //   this.initMsal();
        this.initAppEvents();
    }

    // private initMsal() {
    //     this.logger.info('AppService => app.service:init() invoked');
    //    const app = MSALInstanceFactory();
    //     const account = app.getAllAccounts();
    //     console.log(account);
    //     if (account.length) {
    //         this.http
    //             .get(`${environment.swapFunctionAuthBaseUri}user/me`)
    //             .toPromise()
    //             .then(this.meResponse.bind(this))
    //             .catch((response) => {
    //                 console.log('user/me failed', response);
    //                 app.logout();
    //             });
    //     }
    // }

    private initAppEvents() {
        this.subscriptions.add(this.eventService.on(AppEvent.BadRequestEvent).subscribe({ next: this.onBadRequestEvent.bind(this) }));
        this.subscriptions.add(
            this.eventService.on(AppEvent.UnhandledExceptionEvent).subscribe({ next: this.onUnhandledExceptionEvent.bind(this) })
        );
    }

    private onBadRequestEvent(args: BadRequestEventArgs): void {
        console.log(args);
        if (!args.actionResult) {
            return;
        }
        const functionalMessages = args.actionResult.messages.map((m) => m.message);
        this.matSnackBar.open(functionalMessages.join(', '));
    }

    private onUnhandledExceptionEvent(): void {}
}
