<ng-container *ngIf="swapperMe">
    <div style="text-align: right">
        <app-button [routerLink]="'/' + circleId + '/swapper'" [display]="displayTypes.Link">Wijzig je gegevens</app-button>
        &nbsp;
        <app-button [display]="displayTypes.Link" (click)="logout()">Uitloggen</app-button>
    </div>

    <p>{{ createWelcome() }} {{ swapperMe.name }},</p>
</ng-container>

<ng-container *ngIf="!swapperMe">
    <app-mobile-comment>
        Zorg ervoor dat je deze stap op jouw mobiele telefoon uitvoert, zodat je bij de volgende scan direct bent ingelogd.
    </app-mobile-comment>
    <h1>Yes!</h1>
    <p *ngIf="bag">Je hebt tas {{ bag.name }} ontvangen.</p>

    <app-auth-swap-token></app-auth-swap-token>
</ng-container>

<div [ngSwitch]="bagStatus">
    <ng-container *ngSwitchCase="bagStatusEnum.New">
        <h1>Yes!</h1>
        <p *ngIf="bag">
            Je hebt tas {{ bag.name }}
            ontvangen.
        </p>
        <div style="margin-bottom: 1rem">Klik op onderstaande button om te bevestigen dat jij deze tas echt hebt ontvangen.</div>
        <app-button [inProgress]="isLoading" (click)="onReceivedBag()">Bevestig ontvangst</app-button>
    </ng-container>

    <ng-container *ngSwitchCase="bagStatusEnum.ReadyToDress">
        <ng-container *ngIf="justAccepted">
            <h1>Tijd om te shoppen</h1>
            <p>Kijk op je gemak wat voor leuks er in deze tas zit en welke items jij een tweede leven kunt geven.</p>
            <p>
                <span style="font-weight: 700">Ben je klaar om de tas door te geven?</span>
                Scan de QR code nog een keer om te zien naar wie deze tas geswapt kan worden. Of ben je misschien al klaar om de tas door te
                geven?
                <app-button [display]="displayTypes.Link" (click)="onSetProposedSwap()" class="btn">
                    Kijk dan hier voor de volgende swapper.
                </app-button>
            </p>
            <p></p>
        </ng-container>

        <ng-container *ngIf="!justAccepted">
            <h1>Uitgeshopt?</h1>
            <p style="margin-bottom: 1rem">
                Stop er wat mooie leftovers uit jouw kast in (heb je deze keer niets te geven, ook prima). Wil je weten wat er wel of niet
                in de tas kan lees dan
                <app-button [display]="displayTypes.Link" [routerLink]="'/' + circleId">deze tips</app-button>
                . Klik de button voor de volgende swapper!
            </p>
            <app-button (click)="onSetProposedSwap()" class="btn">Swap Forward die tas</app-button>
        </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="bagStatusEnum.ReadyToSwap">
        <h1>Swappen maar!</h1>
        <p>Stuur de volgende swapper een appje om af te spreken wanneer je de tas zal brengen. De volgende swapper is:</p>

        <ng-container *ngIf="proposedOwner">
            <ul style="margin-top: 10px">
                <li style="font-weight: 700">{{ proposedOwner.name }}</li>
                <li>
                    <app-whats-app
                        [text]="'Hoi ' + proposedOwner.name + ', '"
                        [mobile]="proposedOwner.mobileNumber"
                        linkText="Stuur een WhatsApp-je"
                    ></app-whats-app>
                </li>
                <li style="font-weight: 700">of</li>
                <li>
                    <a class="mobile-btn" [attr.href]="'tel:' + proposedOwner.mobileNumberClean">bel {{ proposedOwner.mobileNumber }}</a>
                </li>
            </ul>
        </ng-container>
    </ng-container>
</div>
<footer>
    <p>Meer weten over deze Swap Cirkel, of heb je een vraag?</p>
    <app-button [display]="displayTypes.Link" [routerLink]="'/' + circleId">Meer info</app-button>
</footer>
