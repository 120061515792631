import { Injectable } from '@angular/core';
import { ApiServiceBase, SwapUri } from '../api-service-base';
import { CircleDetailDto, CircleDto, CirclePublicDto } from './circle-api.models';

@Injectable()
export class CircleApiService extends ApiServiceBase {
    fetchAuthCircles(): Promise<CircleDto[]> {
        return this.get<CircleDto[]>(`circle`, SwapUri.Authorization);
    }

    fetchPublicCircle(circleId: string): Promise<CirclePublicDto> {
        return this.get(`circle/${circleId}`, SwapUri.Public);
    }

    fetchAuthCircle(circleId: string): Promise<CircleDetailDto> {
        return this.get<CircleDetailDto>(`circle/${circleId}`, SwapUri.Authorization);
    }
}
