import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

interface Event {
    key: any;
    data?: any;
}

@Injectable()
export class EventService {
    private eventBus = new Subject<Event>();

    publish(key: any, data?: any) {
        this.eventBus.next({ key, data });
    }

    on<T>(key: any): Observable<T> {
        return this.eventBus
            .asObservable()
            .pipe(filter((event: Event) => event.key === key))
            .pipe(map((event) => event.data as T));
    }
}
