import { Component, OnInit, OnDestroy, ViewEncapsulation, Renderer2 } from '@angular/core';

@Component({
    selector: 'app-home',
    templateUrl: './swap.component.html',
    styleUrls: ['./swap.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SwapComponent implements OnInit, OnDestroy {
    constructor(private renderer: Renderer2) {}

    ngOnInit(): void {
        this.renderer.addClass(document.body, 'swap');
    }

    ngOnDestroy(): void {
        this.renderer.removeClass(document.body, 'swap');
    }
}
