import { Component, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class LogoutComponent implements OnInit {
    public circleId: string;

    ngOnInit(): void {
    }
}
