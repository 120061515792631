export interface BadRequestResultMessage {
    errorCode: string;
    message: string;
}

export interface BadRequestResult {
    messages: BadRequestResultMessage[];
}

export class BadRequestEventArgs {
    constructor(public actionResult: BadRequestResult) {}
}

export class CommandResponse {
    newId: string;
    messages: BadRequestResultMessage[];
}
