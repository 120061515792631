import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable()
export class MonitorApiService {
    constructor(protected http: HttpClient) {}
    isActive(): Promise<boolean> {
        return this.http.get<boolean>(`${environment.swapFunctionBaseUri}isActive`).toPromise();
    }
}
