<h1>Maak je eigen swap circle</h1>

Start eigen cirkel starten in je eigen omgeving. Meldt je dan hieronder aan.

<form *ngIf="form" [formGroup]="form">
    <input placeholder="Naam swap cirkel" [formControlName]="control.circleName" class="swapInput" />
    <input type="text" placeholder="0612345678" [formControlName]="control.mobile" (keypress)="numberOnly($event)" class="swapInput" />
    <app-location-input
        *ngIf="locationInputActive"
        (locationInputDeactivated)="onLocationInputDeactivated()"
        (locationInputAccepted)="onLocationInputAccepted($event)"
    ></app-location-input>
    <app-button (click)="onLocationInputActivated()">locatie</app-button>

    <app-button (click)="onSaving()" [inProgress]="isSaving" [disabled]="form.invalid">Start je cirkel</app-button>
</form>
