export enum AuthenticationRoutes {
    // AuthRedirect = 'auth-redirect',
    // AuthLoggedOut = 'uitgelogd',
}

export enum AdminRoutes {
    AdminParent = '',
    AdminHome = 'admin',
    AdminCircle = 'admin/:circleId',
    AdminSignUpOwner = 'admin/sign-up-owner',
}

export enum OwnerRoutes {
    OwnerParent = '',
    OwnerHome = 'owner',
    OwnerCircle = 'owner/:circleId',
    OwnerCreateCircle = 'owner/start-je-eigen-cirkel',
}

export enum Swap2Routes {
    Circle = ':circleId',
    Bag = ':circleId/:bagId',
    Register = 'register/:registrationCode',
}

export enum SwapRoutes {
    SwapParent = '',
    Token = 'token/:token',
    Register = 'register/:registrationCode',
    Circle = ':circleId',
    Bag = ':circleId/:bagId',
}

export enum PublicRoutes {
    PublicParent = '',
    Home = '',
    WhatsInItForMe = 'mijn-voordeel',
    Logout = 'logout',
    SignUp = 'aanmelden',
    JoinUs = 'doe-mee',
    HowItWorks = 'zo-werkt-het',
    Mission = 'missie',
    Contact = 'contact',
    NotFound = '404',
}
