<div *ngIf="isSuccessful">
    Je gevraagde wijziging is doorgevoerd.
    <app-button [display]="displayTypes.Link" [routerLink]="'/' + circleId + '/swapper'">Ga verder naar je profiel pagina</app-button>
</div>

<div *ngIf="!isSuccessful">
    <p>Er is iets niet goed gegaan. Het kan zijn dat de link al gebruikt is of verlopen is</p>
    <p>
        <app-button [display]="displayTypes.Link" [routerLink]="'/'">Ga verder naar de homepage.</app-button>
    </p>
</div>
