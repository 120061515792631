import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ApiServiceBase, SwapUri } from '../api-service-base';
import { BagDto } from '../circle/circle-api.models';
import { BagDetailDto, BagStatusDto, Swapper } from './circle-bag-api.models';

@Injectable()
export class CircleBagApiService extends ApiServiceBase {
    constructor(protected http: HttpClient) {
        super(http);
    }

    fetchCircleBag(circleId: string, bagId: string): Promise<BagDetailDto> {
        return this.get<BagDetailDto>(`circle/${circleId}/bag/${bagId}`, SwapUri.Authorization);
    }

    fetchCircleBagPublic(circleId: string, bagId: string) {
        return this.get<BagDto>(`circle/${circleId}/bag/${bagId}`, SwapUri.Public);
    }

    fetchBagStatus(bagId: string) {
        return this.get<BagStatusDto>(`bag/${bagId}/status`, SwapUri.Swap);
    }

    fetchProposedSwapForward(bagId: string) {
        return this.get<Swapper>(`bag/${bagId}/proposed-swap`, SwapUri.Swap);
    }

    swapToLogInModel(bagId: string) {
        return this.http.post(`${environment.swapFunctionSwapBaseUri}bag/${bagId}/swap-to`, null).toPromise();
    }

    onSetProposedSwap(bagId: string) {
        return this.http.post(`${environment.swapFunctionSwapBaseUri}bag/${bagId}/proposed-swap`, null).toPromise();
    }
}
