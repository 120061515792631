import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { environment } from 'src/environments/environment';
import { QueryResult } from '../core/api/shared.models';
import { AuthorizationModel, Roles, StateService } from '../core/state.service';

@Injectable()
export class RoleGuard implements CanActivateChild {
    constructor(private http: HttpClient, private router: Router, private stateService: StateService) {}
    public async canActivateChild(route: ActivatedRouteSnapshot): Promise<boolean> {
        const user = await this.http
            .get<QueryResult<AuthorizationModel>>(`${environment.swapFunctionAuthBaseUri}user/me`)
            .toPromise()
            .then((response) => response.data)
            .catch((reason) => console.log(reason));
        if (!user) {
            console.log('user unknown');
            return false;
        }

        this.stateService.me = user;

        const roleNeeded = route.url[0].path.toLowerCase();
        const rolesOwned = user.roles.map((r) => Roles[r].toLowerCase());
        const hasRole = rolesOwned.includes(roleNeeded);
        if (!hasRole) {
            this.router.navigate(['/']);
        }

        return hasRole;
    }

    public async getUserInfo() {
        const response = await fetch('/.auth/me');
        const payload = await response.json();
        const { clientPrincipal } = payload;
        return clientPrincipal;
      }
}
