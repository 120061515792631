<form [formGroup]="emailVerifyForm" class="form">
    <p *ngIf="!showVerifyCodeButton()">
        <input placeholder="e-mailadres" type="email" [formControlName]="control.Email" class="swapInput" />
    </p>
    <p>
        <app-button [disabled]="emailVerifyForm.invalid" (click)="onSendVerifyCode()" *ngIf="!showVerifyCodeButton()">
            {{ sendVerificationCodeButtonText }}
        </app-button>
    </p>

    <ng-container *ngIf="showVerifyCodeButton()">
        <div>
            Code gestuurd naar {{ this.emailAddressUnderVerification }}
            <app-button (click)="onChangeEmailClick()" [display]="displayType.Link">wijzig</app-button>
        </div>
        <input type="text" placeholder="Verificatiecode" [formControlName]="control.EmailVerificationCode" class="swapInput" />
        <app-button (click)="onVerifyCode()" [disabled]="verificationCodeIsInvalid()">{{ verifyVerificationCodeButtonText }}</app-button>
    </ng-container>
</form>
