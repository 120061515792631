import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { VerifyEmailResult } from 'src/app/components/verify-email/verify-email.component';
import { environment } from 'src/environments/environment';
import { GeoJsonDto, PositionDto } from '../shared.models';

export class RegisterSwapperDto {
    firstName: string;
    email: string;
    emailVerificationCode: string;
    location: PositionDto;
    circleId: string;
    circleRegistrationCode: string;
    mobile: string;

    constructor(formValues: any, emailVerification: VerifyEmailResult, circleId: string, circleRegistrationCode: string) {
        Object.assign(this, formValues);
        this.emailVerificationCode = emailVerification.emailVerificationCode;
        this.email = emailVerification.email;
        this.circleRegistrationCode = circleRegistrationCode;
        this.circleId = circleId;
    }
}

export class RegistrationResponseDto {
    public swapToken: string;
}

export class RegistrationCodeVerificationResponseDto {
    public circleId: string;
    public name: string;
    public center: GeoJsonDto;
}
