import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './public/home/home.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatButtonModule } from '@angular/material/button';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { SettingsService } from './core/settings.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SwapBagComponent } from './swap/swap-bag/swap-bag.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { SwapAdminComponent } from './admin/swap-admin/swap-admin.component';
import { MatSelectModule } from '@angular/material/select';
import { SwapAdminCircleComponent } from './admin/swap-admin-circle/swap-admin-circle.component';
import { ErrorInterceptor } from './error/error.interceptor';
import { SwapCircleComponent } from './swap/swap-circle/swap-circle.component';

import { AppService } from './core/app.service';
import { StateService } from './core/state.service';
import { SwapAdminHomeComponent } from './admin/swap-admin-home/swap-admin-home.component';
import { SwapOwnerComponent } from './owner/swap-owner/swap-owner.component';
import { SwapOwnerHomeComponent } from './owner/swap-owner-home/swap-owner-home.component';
import { RoleGuard } from './guards/role-guard';
import { CircleBagApiService } from './core/api/circle-bag/circle-bag-api.service';
import { MatTableModule } from '@angular/material/table';
import { SwapTokenInterceptor } from './interceptors/swap-token.interceptor';
import { AuthSwapTokenComponent } from './components/auth-swap-token/auth-swap-token.component';
import { SwapStateService } from './core/swap-state.service';
import { SwapService } from './swap/services/swap.services';
import { WhatsAppComponent } from './components/whats-app/whats-app.component';
import { ButtonComponent } from './components/button/button.component';
import { EventService } from './core/event.service';
import { SwapRegisterComponent } from './swap/swap-register/swap-register.component';
import { SwapOwnerCircleComponent } from './owner/swap-owner-circle/swap-owner-circle.component';
import { LocationInputComponent } from './components/location-input/location-input.component';
import { LocationService } from './core/location.service';
import { CircleApiService } from './core/api/circle/circle-api.service';
import { CircleSwapperApiService } from './core/api/circle-swapper/circle-swapper-api.service';
import { CircleOwnerPlotComponent } from './components/circle-owner-plot/circle-owner-plot.component';
import { MainMenuComponent } from './components/main-menu/main-menu.component';
import { MatMenuModule } from '@angular/material/menu';
import { VerifyEmailComponent } from './components/verify-email/verify-email.component';
import { StartupComponent } from './components/startup-message/startup.component';
import { MonitorApiService } from './core/api/monitor/monitor-api.service';
import { SwapComponent } from './swap/swap/swap.component';
import { LinkComponent } from './components/link/link.component';
import { PublicComponent } from './public/public/public.component';
import { WhatsInItForMeComponent } from './public/whats-in-it-for-me/whats-in-it-for-me.component';
import { HowItWorksComponent } from './public/how-it-works/how-it-works.component';
import { MissionComponent } from './public/mission/mission.component';
import { ContactComponent } from './public/contact/contact.component';
import { MobileCommentComponent } from './components/mobile-comment/mobile-comment.component';
import { MatCardModule } from '@angular/material/card';
import { SwapAdminSignUpOwnerComponent } from './admin/swap-admin-sign-up-owner/swap-admin-sign-up-owner.component';
import { LogoutComponent } from './public/logout/logout.component';
import { JoinUsComponent } from './public/join-us/join-us.component';
import { AuthLoggedOutComponent } from './auth/auth-logged-out/auth-logged-out.component';
import { NotFoundComponent } from './swap/not-found/not-found.component';
import { SwapOwnerCreateCircleComponent } from './owner/swap-owner-circle-create/swap-owner-create-circle.component';
import { SwapSwapperComponent } from './swap/swap-swapper/swap-swapper.component';
import { MatExpansionModule } from '@angular/material/expansion';
import { SwapperCommandApiService } from './core/api/swapper-command/swapper-command-api.service';
import { SwapTokenComponent } from './swap/swap-token/swap-token.component';

@NgModule({
    declarations: [
        AppComponent,
        PublicComponent,
        LinkComponent,
        HomeComponent,
        WhatsInItForMeComponent,
        JoinUsComponent,
        LogoutComponent,
        HowItWorksComponent,
        MissionComponent,
        ContactComponent,
        SwapBagComponent,
        SwapCircleComponent,
        SwapAdminCircleComponent,
        SwapAdminSignUpOwnerComponent,
        SwapAdminComponent,
        SwapAdminHomeComponent,
        SwapOwnerComponent,
        SwapComponent,
        SwapOwnerHomeComponent,
        AuthSwapTokenComponent,
        CircleOwnerPlotComponent,
        VerifyEmailComponent,
        WhatsAppComponent,
        ButtonComponent,
        LinkComponent,
        SwapRegisterComponent,
        SwapOwnerCircleComponent,
        LocationInputComponent,
        MobileCommentComponent,
        MainMenuComponent,
        StartupComponent,
        AuthLoggedOutComponent,
        NotFoundComponent,
        SwapOwnerCreateCircleComponent,
        SwapSwapperComponent,
        SwapTokenComponent,
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatInputModule,
        MatButtonModule,
        MatFormFieldModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
        DragDropModule,
        FlexLayoutModule,
        FontAwesomeModule,
        MatSelectModule,
        MatTableModule,
        MatMenuModule,
        MatCardModule,
        MatExpansionModule,
    ],
    exports: [
        MatButtonModule,
        DragDropModule,
        MatFormFieldModule,
        MatSelectModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatSnackBarModule,
    ],
    providers: [
        StateService,
        SwapService,
        SwapStateService,
        LocationService,
        {
            provide: LOCALE_ID,
            deps: [SettingsService],
            useFactory: (settingsService) => settingsService.getLanguage(),
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: SwapTokenInterceptor,
            multi: true,
        },
        AppService,
        {
            provide: APP_INITIALIZER,
            useFactory: (appService: AppService) => () => appService.init(),
            deps: [AppService, Injector],
            multi: true,
        },
        RoleGuard,
        CircleApiService,
        CircleSwapperApiService,
        MonitorApiService,
        CircleBagApiService,
        SwapperCommandApiService,
        EventService,
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
