import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { AppEvent } from 'src/app/core/api/shared/app.events';
import { EventService } from 'src/app/core/event.service';

export enum DisplayType {
    Button = 1,
    Link,
}

@Component({
    selector: 'app-button',
    templateUrl: './button.component.html',
    styleUrls: ['./button.component.scss'],
})
export class ButtonComponent implements OnInit {
    @Input()
    public display: DisplayType = DisplayType.Button;

    @Input()
    public disabled: boolean;

    @Input()
    public inProgress = false;

    @Output()
    public inProgressChange = new EventEmitter<boolean>();

    public displayType = DisplayType;

    private subscriptions = new Subscription();

    constructor(private eventService: EventService) {}

    ngOnInit(): void {
        this.subscriptions.add(this.eventService.on(AppEvent.BadRequestEvent).subscribe({ next: this.onBadRequestEvent.bind(this) }));
    }

    private onBadRequestEvent(): void {
        this.inProgress = false;
        this.inProgressChange.emit(false);
    }
}
