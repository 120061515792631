import { Component, OnInit, OnDestroy } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { VerifyEmailResult } from 'src/app/components/verify-email/verify-email.component';
import { GeoJsonDto } from 'src/app/core/api/shared.models';
import {
    RegisterSwapperDto,
    RegistrationCodeVerificationResponseDto,
} from 'src/app/core/api/swapper-registration/swapper-registration-api.models';
import { SwapperRegistrationApiService } from 'src/app/core/api/swapper-registration/swapper-registration-api.service';
import { SwapperRegistrationService } from '../services/swapper-registration-service';

export enum Control {
    Location = 'location',
    Mobile = 'mobile',
    FirstName = 'firstName',
}

export enum RegistrationStep {
    Email,
    Coordinates,
    Contact,
}

@Component({
    selector: 'app-swap-register',
    templateUrl: './swap-register.component.html',
    styleUrls: ['./swap-register.component.scss'],
    providers: [SwapperRegistrationService, SwapperRegistrationApiService],
})
export class SwapRegisterComponent implements OnInit {
    public form: FormGroup;
    public control = Control;
    public locationInputActive = false;
    public locationReceived = false;
    public circle: RegistrationCodeVerificationResponseDto;
    public circleRegistrationCode: string;
    public activeRegistrationStep = RegistrationStep.Email;
    public registrationStepTypes = RegistrationStep;

    public emailVerified: VerifyEmailResult;

    constructor(private swapperRegistrationService: SwapperRegistrationService, private route: ActivatedRoute, private router: Router) {}

    public async ngOnInit() {
        this.initForm();
        this.circleRegistrationCode = this.route.snapshot.params.registrationCode;
        this.circle = await this.swapperRegistrationService.checkRegistrationCode(this.circleRegistrationCode);
    }

    public onLocationInputActivated() {
        this.locationInputActive = true;
    }

    public onLocationInputDeactivated() {
        this.locationInputActive = false;
    }

    public onLocationInputAccepted(location: any) {
        this.form.get(Control.Location).setValue(location);
        this.locationInputActive = false;
        this.locationReceived = true;
        this.activeRegistrationStep = RegistrationStep.Contact;
    }

    public onRegister() {
        this.swapperRegistrationService.registerSwapper(
            new RegisterSwapperDto(this.form.value, this.emailVerified, this.circle.circleId, this.circleRegistrationCode)
        );
        this.router.navigate([this.circle.circleId], { queryParams: { message: 'welcome' } });
    }

    public async onEmailVerified(verifyEmailResult: VerifyEmailResult) {
        this.emailVerified = verifyEmailResult;
        this.activeRegistrationStep = RegistrationStep.Coordinates;
    }

    public initForm() {
        this.form = new FormGroup({
            [Control.Location]: new FormControl(null, Validators.required),
            [Control.FirstName]: new FormControl('', Validators.required),
            [Control.Mobile]: new FormControl('', {
                validators: [Validators.required, Validators.pattern('[0-9 ]{10}')],
            }),
        });
    }

    public emailIsVerified() {
        return !!this.emailVerified;
    }

    public formIsValid() {
        return this.form.valid && this.emailIsVerified() && this.circle;
    }

    public numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
}
