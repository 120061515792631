import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthorizationModel, StateService } from 'src/app/core/state.service';

@Component({
    templateUrl: './whats-in-it-for-me.component.html',
    styleUrls: ['./whats-in-it-for-me.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class WhatsInItForMeComponent implements OnInit {
    constructor(private stateService: StateService) {}

    loggedInUser$: Observable<AuthorizationModel>;
    ngOnInit(): void {
        this.loggedInUser$ = this.stateService.getMe();
    }
}
