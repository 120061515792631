import { Injectable } from '@angular/core';
import {
    RegisterSwapperDto,
    RegistrationCodeVerificationResponseDto,
} from 'src/app/core/api/swapper-registration/swapper-registration-api.models';
import { SwapperRegistrationApiService } from 'src/app/core/api/swapper-registration/swapper-registration-api.service';
import { SwapStateService } from 'src/app/core/swap-state.service';

@Injectable()
export class SwapperRegistrationService {
    constructor(private circleSwapperApiService: SwapperRegistrationApiService, private stateService: SwapStateService) {}

    public async checkRegistrationCode(registrationCode: string): Promise<RegistrationCodeVerificationResponseDto> {
        return await this.circleSwapperApiService.checkRegistrationCode(registrationCode);
    }

    public sendVerificationCode(emailAddress: string) {
        this.circleSwapperApiService.sendVerificationCode(emailAddress);
    }

    public verifyVerificationCode(emailAddress: string, code: string): Promise<object> {
        return this.circleSwapperApiService.verifyVerificationCode(emailAddress, code);
    }

    public async registerSwapper(registerSwapper: RegisterSwapperDto) {
        const swapToken = await this.circleSwapperApiService.registerSwapper(registerSwapper);
        this.stateService.setSwapperId(registerSwapper.circleId, swapToken);
    }
}
