import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { Subscription } from 'rxjs';
import { AuthorizationModel, Roles, StateService } from 'src/app/core/state.service';
import { DisplayType } from '../button/button.component';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent implements OnInit {
    @Input()
    me: AuthorizationModel;

    public displayTypes = DisplayType;
    private subscription = new Subscription();

    constructor(private stateService: StateService) {}

    ngOnInit(): void {
        this.subscription.add(
            this.stateService.getMe().subscribe({
                next: (value) => {
                    this.me = value;
                },
            })
        );
    }

    isLoggedIn() {
        return !!this.me;
    }

    hasAdmin() {
        return this.me.roles.includes(Roles.Admin);
    }

    hasOwner() {
        return this.me.roles.includes(Roles.Owner);
    }
}
