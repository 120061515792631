import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { CircleSwapperApiService } from 'src/app/core/api/circle-swapper/circle-swapper-api.service';
import { PositionDto } from 'src/app/core/location.service';

export enum Control {
    location = 'location',
    mobile = 'mobile',
    circleName = 'circleName',
}

@Component({
    templateUrl: './swap-owner-create-circle.component.html',
    styleUrls: ['./swap-owner-create-circle.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SwapOwnerCreateCircleComponent implements OnInit {
    public locationInputActive = false;
    public locationReceived = false;
    public isSaving = false;

    public form: FormGroup;
    public control = Control;

    constructor(private circleSwapperApiService: CircleSwapperApiService, private router: Router) {}

    public ngOnInit(): void {
        this.setFormGroup();
    }

    public onLocationInputActivated() {
        this.locationInputActive = true;
    }

    public onLocationInputDeactivated() {
        this.locationInputActive = false;
    }

    public onLocationInputAccepted(location: PositionDto) {
        this.form.get(Control.location).setValue(location);
        this.locationInputActive = false;
        this.locationReceived = true;
    }

    public setFormGroup() {
        this.form = new FormGroup({
            [Control.circleName]: new FormControl('', Validators.required),
            [Control.mobile]: new FormControl('', {
                validators: [Validators.required, Validators.pattern('[0-9 ]{10}')],
            }),
            [Control.location]: new FormControl(null, Validators.required),
        });
    }

    public async onSaving() {
        this.isSaving = true;
        var circleId = await this.circleSwapperApiService.startCircle(this.form.value);
        this.isSaving = false;
        this.router.navigate(['owner', circleId['newId']]);
    }

    public numberOnly(event): boolean {
        const charCode = event.which ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;
    }
}
