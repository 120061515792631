import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { SwapperCommandDto, SwapperCommandResultDto } from './swapper-command-api.models';

@Injectable()
export class SwapperCommandApiService {
    constructor(protected http: HttpClient) {}

    async postSwapperCommand(swapperCommand: SwapperCommandDto) {
        return await this.http.post(`${environment.swapFunctionBaseUri}swapper/command`, swapperCommand).toPromise();
    }

    async postSwapperCommandToken(token: string): Promise<SwapperCommandResultDto> {
        return await this.http
            .post<SwapperCommandResultDto>(`${environment.swapFunctionBaseUri}swapper/command/${token}`, {})
            .toPromise<SwapperCommandResultDto>();
    }
}
