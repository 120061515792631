<h1>Missie</h1>
<p>
    Er is steeds meer mode beschikbaar. En vaak ook nog eens tegen heel toegankelijke prijzen. Maar het milieu betaalt hiervoor vaak de
    prijs. Het massale, razendsnelle produceren, gebruiken en afdanken van kleding leidt bijvoorbeeld tot CO2-uitstoot, plasticsoep en
    vervuiling van grond en water. Daarnaast wordt er onnodig veel kleding vernietigd, …..en we hebben maar één aardbol waar we zuinig op
    moeten zijn.
</p>
<h1>Wist je dat?</h1>
<p>
    In één spijkerbroek tot 10000 liter water verborgen zit? Dit staat gelijk aan 200 dagen douchen. Er wel 32 kg CO2 vrijkomt bij het
    produceren van een jeans? Dit staat gelijk aan 150 kilometer autorijden. De 16 grootste zeeschepen die producten (waaronder
    spijkerbroeken) vervoeren stoten evenveel zwavel uit als alle auto’s in de wereld bij elkaar. Bij het verbouwen van katoen schadelijke
    stoffen zoals kunstmest en pesticiden worden gebruikt? Dit is erg schadelijk voor de natuur. 2,5% van alle beschikbare landbouwgrond
    gebruikt wordt voor het verbouwen van katoen? Dit is een oppervlakte dat gelijk staat aan 10 keer Nederland. Super gaaf dus dat je mee
    doet met Swap Forward en zo bijdraagt aan een stuk duurzaamheid.
</p>
<p>
    Swap Forward vindt het heel leuk dat als je een compliment over je nieuwe kleding krijgt, je diegene vertelt over dit geweldige
    initiatief en dat je het dus via de Swap Forward tas heb gekregen! Nieuwe swappers zijn welkom en het is geweldig als zo steeds meer
    mensen het verschil kunnen maken!
</p>
