import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';

@Component({
    selector: 'app-swap-owner',
    templateUrl: './swap-owner.component.html',
    styleUrls: ['./swap-owner.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SwapOwnerComponent {
    constructor(private snackBar: MatSnackBar, private router: Router) {}
}
