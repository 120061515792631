<ul style="width: 600px; margin: 0 auto">
    <li class="title">
        <div>
            <span>Naam</span>
        </div>
        <div>
            <span>Aantal geaccepteerde swappers</span>
        </div>
        <div>
            <span>Aantal geregistreerde swappers</span>
        </div>
        <div>
            <span>Aantal verwijderde swappers</span>
        </div>
        <div>
            <span>Aantal swaps</span>
        </div>
        <div>
            <span>Aantal tassen</span>
        </div>
        <div>
            <span>Aantal actieve tassen</span>
        </div>
    </li>
    <li *ngFor="let circle of circles" [value]="circle.id" class="values">
        <div>
            <app-button [display]="displayTypes.Link" routerLink="{{ circle.id }}">{{ circle.name }}</app-button>
        </div>
        <div>
            {{ circle.numberOfAcceptedSwapper }}
        </div>
        <div>
            {{ circle.numberOfRegisteredSwapper }}
        </div>
        <div>
            {{ circle.numberOfDeletedSwapper }}
        </div>
        <div>
            {{ circle.numberOfSwaps }}
        </div>
        <div>
            {{ circle.numberOfBags }}
        </div>
        <div>
            {{ circle.numberOfActiveBags }}
        </div>
    </li>
</ul>

<!-- <a [routerLink]="['sign-up-owner']">Aanmeldingen owners</a> -->
