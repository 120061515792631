<ng-container *ngIf="swapperMe">
    <div style="text-align: right">
        <app-button [display]="displayTypes.Link" (click)="logout()">Uitloggen</app-button>
    </div>

    <p>{{ createWelcome() }} {{ swapperMe.name }},</p>
</ng-container>

<ng-container *ngIf="!swapperMe">
    <app-auth-swap-token></app-auth-swap-token>
</ng-container>

<div *ngIf="commandSend">Er is zojuist een email naar je gestuurd om je wijziging te accepteren.</div>
<div *ngIf="swapperMe && circle && !commandSend">
    <p>Hieronder kun je je gegevens wijzigen voor cirkel "{{ circle.name }}":</p>

    <mat-expansion-panel
        *ngFor="let option of commandOptions"
        [expanded]="option.type === selectedCommand.type"
        (opened)="setCommand(option)"
    >
        <mat-expansion-panel-header>
            <mat-panel-title>{{ createTitle(option.title) }}</mat-panel-title>
        </mat-expansion-panel-header>
        <p>
            <input
                [type]="option.inputType"
                [placeholder]="option.placeholder"
                [(ngModel)]="option.inputValue"
                (change)="onChangeCheckbox($event)"
                class="swapInput"
            />

            {{ createText(option) }}
            <app-button [disabled]="isDisabled(option.type)" (click)="onSubmit()">{{ createButtonTitle(option) }}</app-button>
        </p>
    </mat-expansion-panel>
</div>

<footer>
    <p>Meer weten over deze Swap Cirkel, of heb je een vraag?</p>
    <app-button [display]="displayTypes.Link" [routerLink]="'/' + circleId">Meer info</app-button>
</footer>
