import { NgModule } from '@angular/core';
import { Routes, RouterModule, UrlSegment } from '@angular/router';
import { HomeComponent } from './public/home/home.component';
import { SwapBagComponent } from './swap/swap-bag/swap-bag.component';
import { SwapAdminComponent } from './admin/swap-admin/swap-admin.component';
import { SwapAdminCircleComponent } from './admin/swap-admin-circle/swap-admin-circle.component';
import { AdminRoutes, OwnerRoutes, PublicRoutes, SwapRoutes } from './app-routing.model';
import { SwapCircleComponent } from './swap/swap-circle/swap-circle.component';
import { SwapAdminHomeComponent } from './admin/swap-admin-home/swap-admin-home.component';
import { SwapOwnerComponent } from './owner/swap-owner/swap-owner.component';
import { SwapOwnerHomeComponent } from './owner/swap-owner-home/swap-owner-home.component';
import { RoleGuard } from './guards/role-guard';
import { SwapRegisterComponent } from './swap/swap-register/swap-register.component';
import { SwapOwnerCircleComponent } from './owner/swap-owner-circle/swap-owner-circle.component';
import { SwapComponent } from './swap/swap/swap.component';
import { PublicComponent } from './public/public/public.component';
import { WhatsInItForMeComponent } from './public/whats-in-it-for-me/whats-in-it-for-me.component';
import { ContactComponent } from './public/contact/contact.component';
import { MissionComponent } from './public/mission/mission.component';
import { HowItWorksComponent } from './public/how-it-works/how-it-works.component';
import { SwapAdminSignUpOwnerComponent } from './admin/swap-admin-sign-up-owner/swap-admin-sign-up-owner.component';
import { LogoutComponent } from './public/logout/logout.component';
import { AuthLoggedOutComponent } from './auth/auth-logged-out/auth-logged-out.component';
import { JoinUsComponent } from './public/join-us/join-us.component';
import { NotFoundComponent } from './swap/not-found/not-found.component';
import { SwapOwnerCreateCircleComponent } from './owner/swap-owner-circle-create/swap-owner-create-circle.component';
import { SwapSwapperComponent } from './swap/swap-swapper/swap-swapper.component';
import { SwapTokenComponent } from './swap/swap-token/swap-token.component';

const guidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
const circleIdMatcher = (url: UrlSegment[]) => {
    return url.length === 1 && url[0].path.match(guidRegex)
        ? {
              consumed: url,
              posParams: {
                  circleId: new UrlSegment(url[0].path, {}),
              },
          }
        : null;
};

const circleIdAndSwapperMatcher = (url: UrlSegment[]) => {
    return url.length === 2 && url[0].path.match(guidRegex) && url[1].path === 'swapper'
        ? {
              consumed: url,
              posParams: {
                  circleId: new UrlSegment(url[0].path, {}),
              },
          }
        : null;
};

const bagIdAndCircleIdMatcher = (url: UrlSegment[]) => {
    return url.length === 2 && url[0].path.match(guidRegex)
        ? {
              consumed: url,
              posParams: {
                  circleId: new UrlSegment(url[0].path, {}),
                  bagId: new UrlSegment(url[1].path, {}),
              },
          }
        : null;
};

const routes: Routes = [
    {
        path: PublicRoutes.PublicParent,
        component: PublicComponent,
        children: [
            //{ path: AuthenticationRoutes.AuthLoggedOut, component: AuthLoggedOutComponent },
            { path: PublicRoutes.Home, component: HomeComponent },
            { path: PublicRoutes.WhatsInItForMe, component: WhatsInItForMeComponent },
            // { path: PublicRoutes.JoinUs, component: JoinUsComponent },
            // { path: PublicRoutes.SignUp, component: SignUpComponent },
            { path: PublicRoutes.Logout, component: LogoutComponent },
            { path: PublicRoutes.HowItWorks, component: HowItWorksComponent },
            { path: PublicRoutes.Mission, component: MissionComponent },
            { path: PublicRoutes.Contact, component: ContactComponent },
        ],
    },
    {
        path: AdminRoutes.AdminParent,
        canActivateChild: [RoleGuard],
        component: SwapAdminComponent,
        children: [
            {
                path: AdminRoutes.AdminHome,
                component: SwapAdminHomeComponent,
            },
            {
                path: AdminRoutes.AdminSignUpOwner,
                component: SwapAdminSignUpOwnerComponent,
            },
            {
                path: AdminRoutes.AdminCircle,
                component: SwapAdminCircleComponent,
            },
        ],
    },
    {
        path: OwnerRoutes.OwnerParent,
        canActivateChild: [RoleGuard],
        component: SwapOwnerComponent,
        children: [
            {
                path: OwnerRoutes.OwnerHome,
                component: SwapOwnerHomeComponent,
            },
            {
                path: OwnerRoutes.OwnerCreateCircle,
                component: SwapOwnerCreateCircleComponent,
            },
            {
                path: OwnerRoutes.OwnerCircle,
                component: SwapOwnerCircleComponent,
            },
        ],
    },
    {
        path: SwapRoutes.SwapParent,
        component: SwapComponent,
        children: [
            { path: SwapRoutes.Register, component: SwapRegisterComponent },
            { path: SwapRoutes.Token, component: SwapTokenComponent },
            {
                matcher: circleIdMatcher,
                component: SwapCircleComponent,
            },
            {
                matcher: circleIdAndSwapperMatcher,
                component: SwapSwapperComponent,
            },
            {
                matcher: bagIdAndCircleIdMatcher,
                component: SwapBagComponent,
            },
            { path: PublicRoutes.NotFound, component: NotFoundComponent },
        ],
    },
    { path: '**', redirectTo: '' },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { anchorScrolling: 'enabled', relativeLinkResolution: 'legacy' })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
