import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-circle-owner-plot',
    templateUrl: './circle-owner-plot.component.html',
    styleUrls: ['./circle-owner-plot.component.scss'],
})
export class CircleOwnerPlotComponent {
    constructor() {}
}
