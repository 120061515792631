import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { SwapperMeDto } from './api/circle-swapper/circle-swapper-api.models';
import { BagStatus } from './api/shared/shared.enums';

@Injectable()
export class SwapStateService {
    //    private bagStatus$ = new BehaviorSubject<BagStatus>(BagStatus.None);
    private swapperMe$ = new BehaviorSubject<SwapperMeDto>(null);

    constructor() {}

    // public set bagStatus(status: BagStatus) {
    //     this.bagStatus$.next(status);
    // }

    // public getStatus() {
    //     return this.bagStatus$.asObservable();
    // }

    public set swapperMe(swapper: SwapperMeDto) {
        this.swapperMe$.next(swapper);
    }

    public get swapperMe(): SwapperMeDto {
        return this.swapperMe$.getValue();
    }

    public getSwapperMe() {
        return this.swapperMe$;
    }

    public getSwapperId(circleId: string): string {
        return localStorage.getItem(circleId);
    }

    public setSwapperId(circleId: string, swapToken: string) {
        localStorage.setItem(circleId, swapToken);
    }

    public logout(circleId: string) {
        localStorage.removeItem(circleId);
        this.swapperMe$.next(null);
    }
}
