import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { MonitorApiService } from 'src/app/core/api/monitor/monitor-api.service';

@Component({
    selector: 'app-startup',
    templateUrl: './startup.component.html',
    styleUrls: ['./startup.component.scss'],
})
export class StartupComponent implements OnInit {
    @Output()
    public startup = new EventEmitter<boolean>();
    public isInitializing = true;
    public initialMessage = '';
    public initialMessages = [
        'Momentje, we zijn voor je bezig.',
        'Om energie te besparen schakelen we onze server na verloop van tijd uit. Daarom duurt het even iets langer.',
        'Wist je dat je ontzettend goed bezig bent, we zijn er bijna.',
        'Je bent een topper, we besparen echt veel energie',
        'Momentje, we zijn voor je bezig.',
        'Om energie te besparen schakelen we onze server na verloop van tijd uit. Daarom duurt het even iets langer.',
        'Wist je dat je ontzettend goed bezig bent, we zijn er bijna.',
        'Je bent een topper, we besparen echt veel energie.',
    ];

    constructor(private monitorApiService: MonitorApiService, private route: ActivatedRoute) {}

    async ngOnInit() {
        if (location.pathname === '/') {
            this.isInitializing = false;
            this.startup.emit(true);
        }

        let delay = 0;
        for (const message of this.initialMessages) {
            setTimeout(() => {
                this.initialMessage = message;
            }, delay);
            delay += 4000;
        }

        var result = await this.monitorApiService.isActive();
        this.isInitializing = false;
        this.startup.emit(result);
    }
}
