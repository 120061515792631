import { Injectable } from '@angular/core';

export interface PositionDto {
    lng: number;
    lat: number;
}

@Injectable()
export class LocationService {
    getCurrentPosition(): Promise<PositionDto> {
        return new Promise((resolve, reject) => {
            navigator.geolocation.getCurrentPosition(
                (resp) => {
                    resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
                },
                (err) => {
                    resolve(null);
                }
            );
        });
    }
}
