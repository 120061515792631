import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-mobile-comment',
    templateUrl: './mobile-comment.component.html',
    styleUrls: ['./mobile-comment.component.scss'],
})
export class MobileCommentComponent implements OnInit {
    public isMobile: boolean;

    constructor() {}

    ngOnInit(): void {
        this.isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    }
}
