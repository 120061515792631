import { Injectable } from '@angular/core';
import { CircleSwapperApiService } from 'src/app/core/api/circle-swapper/circle-swapper-api.service';
import { SwapStateService } from 'src/app/core/swap-state.service';

@Injectable()
export class SwapService {
    constructor(private circleSwapperApiService: CircleSwapperApiService, private stateService: SwapStateService) {}

    public async getSwapperByEmail(circleId: string, email: string) {
        const swapper = await this.circleSwapperApiService.getSwapperByEmail(circleId, email);
        this.stateService.setSwapperId(circleId, swapper.id);
        this.stateService.swapperMe = swapper;
    }

    public async getSwapperById(circleId: string, swapperId: string) {
        const swapper = await this.circleSwapperApiService.getSwapperById(circleId, swapperId);
        this.stateService.setSwapperId(circleId, swapperId);
        this.stateService.swapperMe = swapper;
    }
}
