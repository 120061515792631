import { Injectable, OnDestroy } from '@angular/core';
import localeNl from '@angular/common/locales/nl';
import { registerLocaleData } from '@angular/common';

@Injectable({
    providedIn: 'root',
})
export class SettingsService {
    constructor() {
        registerLocaleData(localeNl, 'nl-NL');
    }

    getLanguage() {
        return 'nl-NL';
    }
}
