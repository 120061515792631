import { Component, Input, OnInit } from '@angular/core';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

@Component({
    selector: 'app-whats-app',
    templateUrl: './whats-app.component.html',
    styleUrls: ['./whats-app.component.scss'],
})
export class WhatsAppComponent implements OnInit {
    private textPrivate: string;
    @Input()
    public set text(value: string) {
        this.textPrivate = value;
        this.rewrite();
    }

    @Input()
    public mobile: string;

    @Input()
    public linkText: string;

    @Input()
    public buttonText: string = 'WhatsApp';

    public whatsAppHref: string;

    public icons = {
        faWhatsapp,
    };

    constructor() {}

    ngOnInit(): void {
        this.rewrite();
    }

    rewrite() {
        let mobileSegment = '';
        if (this.mobile) {
            let whatsAppMobile = this.mobile.replace('-', '');
            whatsAppMobile = whatsAppMobile.substring(1);

            mobileSegment = `/31${whatsAppMobile}`;
        }
        this.whatsAppHref = `https://wa.me${mobileSegment}/?text=${this.textPrivate}`;
    }
}
