<div *ngIf="showWelcomeMessage">Succes! Je telefoon is geregistreerd. Veel swap plezier.</div>
<div *ngIf="circle">
    <h1>Swap Cirkel {{ circle.name }}</h1>
    <h2 *ngIf="circle.bags.length">Deze cirkel bestaat uit de volgende tassen</h2>
    <ul>
        <li *ngFor="let bag of circle.bags">
            {{ bag.name }}
        </li>
    </ul>
    <h2 *ngIf="circle.owners.length" style="margin-top: 1rem">De contactperso(o)n(en) van deze swap cirkel zijn:</h2>
    <ul>
        <li *ngFor="let owner of circle.owners">
            {{ owner }}
        </li>
    </ul>
</div>
<h1 id="tips" name="tips">Wat mag er in de tas?</h1>
<p>
    Schone, fris gewassen dameskleding en schoenen (sokken, panties, lingerie e.d. niet, tenzij gloednieuw). Accessoires zijn van harte
    welkom. Denk hierbij aan riemen, tassen, sjaals e.d. Stop je er zelf pareltjes in dan doen anderen dat ook sneller en maakt het
    natuurlijk extra leuk en gevarieerd. Zie je jouw kleding weer terugkomen in de Swap Forward tas, haal het er dan uit en breng het
    bijvoorbeeld naar de kledingcontainer.
</p>
