<button [disabled]="disabled" class="btn" *ngIf="display === displayType.Button">
    <mat-spinner
        *ngIf="inProgress"
        diameter="20"
        mode="indeterminate"
        style="display: inline-block; margin-right: 10px; margin-top: 5px"
    ></mat-spinner>
    <ng-template [ngTemplateOutlet]="content"></ng-template>
</button>

<a class="btn" *ngIf="display === displayType.Link">
    <mat-spinner
        *ngIf="inProgress"
        diameter="20"
        mode="indeterminate"
        style="display: inline-block; margin-right: 10px; margin-top: 5px"
    ></mat-spinner>
    <ng-template [ngTemplateOutlet]="content"></ng-template>
</a>

<ng-template #content>
    <ng-content></ng-content>
</ng-template>
