<h1>Hoe werkt Swap Forward?</h1>
<p>
    Swap Forward geeft jouw geweldige kledingitems een tweede leven. Uitgekeken op een kledingstuk, toch niet jouw stijl of een miskoop
    gedaan? Stop jouw items in de Swap Forward tas en je maakt er iemand anders blij mee. In diezelfde tas zit ongetwijfeld een item die jij
    heel graag wilt toevoegen aan jouw kledingcollectie. Zo ben je duurzaam bezig, stiekem tóch aan het shoppen en maak je andere deelnemers
    heel blij met jouw geswapte kleding. Jij maakt het verschil, join en word ook een swapper!
</p>
<ol>
    <li>Meld je aan</li>
    <li>Ontvang een Swap Forward tas en scan de QR code</li>
    <li>Shopping time; kijken, passen en uitkiezen</li>
    <li>Eigen kleding die je niet meer draagt en wilt swappen in de tas doen (heb je deze keer niets te geven, ook prima)</li>
    <li>QR code scannen en een afspraak maken om deze tas naar de volgende swapper te brengen</li>
    <li>Tas langsbrengen bij de volgende swapper</li>
</ol>
