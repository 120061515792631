import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { QueryResult } from './shared.models';

export enum SwapUri {
    Authorization,
    Swap,
    Public,
}

@Injectable()
export class ApiServiceBase {
    constructor(protected httpClient: HttpClient) {}

    protected get<TData>(path: string, uriType: SwapUri): Promise<TData> {
        let url = '';
        switch (uriType) {
            case SwapUri.Authorization:
                url = environment.swapFunctionAuthBaseUri;
                break;
            case SwapUri.Public:
                url = environment.swapFunctionBaseUri;
                break;
            case SwapUri.Swap:
                url = environment.swapFunctionSwapBaseUri;
                break;
        }
        return this.httpClient
            .get<QueryResult<TData>>(`${url}${path}`)
            .toPromise()
            .then((result) => result.data);
    }
}
