<p>Heb je je reeds geregistreerd, vul dan je email address in.</p>

<form [formGroup]="formRegister" class="form">
    <p>
        <input placeholder="e-mailadres" type="email" [formControlName]="control.Email" class="swapInput" />
    </p>
    <p>
        <app-button [disabled]="formRegister.invalid" (click)="onLogin()">Login</app-button>
    </p>
</form>

<p>Heb je jezelf nog niet geregistreerd, neem dan contact op met de cirkel coördinator (zie link 'meer info').</p>
