import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { DisplayType } from 'src/app/components/button/button.component';
import { SwapperCommandApiService } from 'src/app/core/api/swapper-command/swapper-command-api.service';

@Component({
    selector: 'app-token-circle',
    templateUrl: './swap-token.component.html',
    styleUrls: ['./swap-token.component.scss'],
})
export class SwapTokenComponent implements OnInit, OnDestroy {
    public subscriptions: Subscription = new Subscription();
    public displayTypes = DisplayType;
    public isSuccessful: boolean = null;
    public circleId: string;

    constructor(private swapperCommandApiService: SwapperCommandApiService, private router: Router, private route: ActivatedRoute) {}

    async ngOnInit() {
        var token = this.route.snapshot.params.token;
        var result = await this.swapperCommandApiService.postSwapperCommandToken(token);

        if (result) {
            this.isSuccessful = true;
            this.circleId = result.circleId;
        } else {
            this.isSuccessful = false;
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
