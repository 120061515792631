<div class="quote">
    Wat te doen met een miskoop of mooie kleding die je niet meer draagt? Maak de wereld een beetje duurzamer en ruil je kleding! Swap
    Forward!
</div>
<div class="router-link-wrapper">
    <a class="router-link" [routerLink]="['how-it-works']">Zo werkt het</a>
    <fa-icon position="top" [icon]="icons.faArrowRight"></fa-icon>
</div>

<div class="quote">Duurzaam bezig en stiekem tóch aan het shoppen! Swap met ons mee en word net zo enthousiast!</div>
<div class="router-link-wrapper">
    <a class="router-link" [routerLink]="['become-a-swapper']">Word een Swapper!</a>
    <fa-icon position="top" [icon]="icons.faArrowRight"></fa-icon>
</div>

<div class="quote">‘Nieuwe’ kleding uitzoeken is natuurlijk super leuk! Maar er zijn nog veel meer redenen om Swapper te worden.</div>
<div class="router-link-wrapper">
    <a class="router-link" [routerLink]="['mission']">Onze missie</a>
    <fa-icon position="top" [icon]="icons.faArrowRight"></fa-icon>
</div>
