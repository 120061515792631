import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';

import { CircleDto } from '../../core/api/circle/circle-api.models';
import { CircleApiService } from 'src/app/core/api/circle/circle-api.service';
import { DisplayType } from 'src/app/components/button/button.component';

@Component({
    selector: 'app-swap-admin-home',
    templateUrl: './swap-admin-home.component.html',
    styleUrls: ['./swap-admin-home.component.scss'],
})
export class SwapAdminHomeComponent implements OnInit, OnDestroy {
    public formPostalCode: FormGroup;
    public title = 'swap forward';
    public displayTypes = DisplayType;

    circles: CircleDto[] = [];

    public isLoading = false;

    public subscriptions: Subscription = new Subscription();

    constructor(private http: HttpClient, private snackBar: MatSnackBar, private circleApiServices: CircleApiService) {}

    async ngOnInit() {
        this.circles = await this.circleApiServices.fetchAuthCircles();
    }

    ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    messageResponse() {
        this.snackBar.open('Klaar');
        setTimeout(() => {
            this.snackBar.dismiss();
        }, 2000);
    }

    onImport() {
        this.http.post(`${environment.swapFunctionAuthBaseUri}admin/import`, null).toPromise().then(this.messageResponse.bind(this));
    }

    onGenerateQrCodes() {
        //  this.http.post(`${environment.swapBaseUri}admin/generate-qr-codes`, null).toPromise().then(this.messageResponse.bind(this));
    }
}
