import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import { EventService } from '../core/event.service';
import { BadRequestEventArgs, BadRequestResult } from '../core/api/shared/api-result.models';
import { AppEvent } from '../core/api/shared/app.events';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router, private eventService: EventService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            tap(
                () => {},
                (e: any) => {
                    if (e instanceof HttpErrorResponse) {
                        const httpError: HttpErrorResponse = e as HttpErrorResponse;
                        switch (httpError.status) {
                            case 400:
                                // IE is return string instead of object
                                const commandResult: BadRequestResult =
                                    typeof httpError.error === 'string' ? JSON.parse(httpError.error) : httpError.error;
                                this.eventService.publish(AppEvent.BadRequestEvent, new BadRequestEventArgs(commandResult));
                                break;
                            default:
                                return throwError(e);
                        }
                    } else {
                        return throwError(e);
                    }
                }
            )
        );
    }
}
