export enum SwapperStatus {
    Registered = 2,
    Accepted = 3,
    Deleted = 4,
}

export enum CircleStatus {
    Pending = 1,
    Active = 2,
    Deleted = 9,
}

export enum BagStatus {
    None = 0,
    New = 1,
    ReadyToDress = 2,
    ReadyToSwap = 3,
    SwapToConfirm = 4,
    UncontrolledSwapToConfirm = 5,
}
