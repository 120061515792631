import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { AuthorizationModel, StateService } from 'src/app/core/state.service';

@Component({
    templateUrl: './join-us.component.html',
    styleUrls: ['./join-us.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class JoinUsComponent implements OnInit {
    constructor(private stateService: StateService) {}

    loggedInUser$: Observable<AuthorizationModel>;
    ngOnInit(): void {
        this.loggedInUser$ = this.stateService.getMe();
    }
}
