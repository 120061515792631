import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { StateService } from './core/state.service';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
    public me: any;
    public isStartup = false;
    public monitorModel: any;
    public isProduction: boolean = true;

    public icons = {
        faExclamation,
    };

    private subscription = new Subscription();

    constructor(private stateService: StateService) {}

    ngOnInit(): void {
        this.subscription.add(
            this.stateService.getMe().subscribe({
                next: (value) => {
                    this.me = value;
                },
            })
        );
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    public onStartup(monitorModel: any) {
        this.isProduction = monitorModel.environment === 'Production';
        if (!this.isProduction) {
            this.monitorModel = monitorModel;
            this.monitorModel.backend = environment.swapFunctionBaseUri;
        }

        this.isStartup = true;
    }
}
