import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SwapStateService } from '../core/swap-state.service';
@Injectable()
export class SwapTokenInterceptor implements HttpInterceptor {
    constructor(private stateService: SwapStateService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const swapperMe = this.stateService.swapperMe;
        if (req.url.startsWith(environment.swapFunctionSwapBaseUri) && swapperMe) {
            let headers = req.headers.set('X-SwapToken', this.stateService.getSwapperId(swapperMe.circleId));
            headers = headers.set('X-CircleId', swapperMe.circleId);
            headers = headers.set('X-SwapperId', swapperMe.id);

            const authReq = req.clone({ headers });

            return next.handle(authReq);
        } else {
            return next.handle(req);
        }
    }
}
